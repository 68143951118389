import React from "react"
import { Typography } from "@material-ui/core"

const Revision = ({ revision }) => {
  if (revision) {
    const url = `https://github.com/packhelp/launchbox/commit/${revision}`
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {revision.substr(0, 6)}
      </a>
    )
  }
  return <span>dev</span>
}

const FooterMessage = () => (
  <Typography
    variant="body2"
    align="center"
    gutterBottom
    style={{ margin: "30px 0 20px 0", color: "#9e9e9e" }}
  >
    Packhelp deployment dashboard, Launchbox (
    <Revision revision={window.__env__.REACT_APP_VERSION} />) –{" "}
    <a
      href="https://www.notion.so/packhelp/LaunchBox-ddaaa5c41e9e4e078210172d722e2bbb"
      target="_blank"
      rel="noopener noreferrer"
    >
      More info
    </a>
    . Have fun{" "}
    <span role="img" aria-label="Celebrate face emoji">
      🥳
    </span>
    !
  </Typography>
)

export default FooterMessage
