import React from "react"

import FormControl from "@material-ui/core/FormControl"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"

import { JOB_STATUS_NEW } from "../const/jobs"

const JobStatusSelect = ({ status, handleChange }) => {
  const jobStatuses = Object.values(JOB_STATUS_NEW)

  return (
    <FormControl className="user-select-form-control">
      <Select
        value={status}
        onChange={handleChange}
        className="user-select-root"
        displayEmpty
      >
        <MenuItem className="user-select-item" value="">
          <span>Status / All Jobs</span>
        </MenuItem>

        {jobStatuses.map((status) => (
          <MenuItem key={status} className="user-select-item" value={status}>
            <span>{status}</span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default JobStatusSelect
