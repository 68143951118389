import React from "react"
import Box from "@material-ui/core/Box"

import ServerRow from "./ServerRow"

const ServersList = ({ servers }) => {
  return (
    <Box>
      {servers.map((server) => {
        return <ServerRow key={server.name} server={server}></ServerRow>
      })}
    </Box>
  )
}

export default ServersList
