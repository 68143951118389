import React from "react"

import Box from "@material-ui/core/Box/Box"
import Button from "@material-ui/core/Button"
import CachedIcon from "@material-ui/icons/Cached"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Chip from "@material-ui/core/Chip"
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress"
import Container from "@material-ui/core/Container/Container"
import FaceIcon from "@material-ui/icons/Face"
import LinearScaleIcon from "@material-ui/icons/LinearScale"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"

import { getBranchLastCommit, updateServerData } from "../../data/data"

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    position: "sticky",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  refresh: {
    position: "absolute",
    top: "5px",
    right: "5px",
    "min-width": "24px",
  },
})

const LoaderWithPadding = () => (
  <Container maxWidth="xl">
    <Box style={{ padding: "30px 0px" }}>
      <CircularProgress />
    </Box>
  </Container>
)

export default function CommitCard(props) {
  const { server } = props

  const [loading, setLoading] = React.useState(true)
  const [branchData, setBranchData] = React.useState(false)
  const [error, setError] = React.useState(false)

  const classes = useStyles()

  const fetchLastCommitAndSet = (serverName, branch) => {
    setLoading(true)
    return getBranchLastCommit(branch)
      .then((commit) => {
        setBranchData(commit)
        updateServerData(serverName, { branchLastCommit: commit.sha })
        setLoading(false)
      })
      .catch((err) => {
        setError(`${err}`)
      })
  }

  React.useEffect(() => {
    fetchLastCommitAndSet(server.name, server.branch)
  }, [server.name, server.branch])

  return (
    <div>
      <Card className={classes.card}>
        {loading ? (
          <LoaderWithPadding />
        ) : (
          <CardContent>
            {error ? (
              <Typography>{error}</Typography>
            ) : (
              <div>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  Last commit on branch on GitHub
                </Typography>
                <Typography variant="h6" component="h3">
                  {branchData.message}
                </Typography>
                <Chip
                  size="small"
                  label={branchData.author}
                  icon={<FaceIcon />}
                />{" "}
                <Chip
                  size="small"
                  label={branchData.sha}
                  icon={<LinearScaleIcon />}
                />
              </div>
            )}
            <Button
              className={classes.refresh}
              onClick={fetchLastCommitAndSet.bind(
                null,
                server.name,
                server.branch
              )}
            >
              <CachedIcon />
            </Button>
          </CardContent>
        )}
      </Card>
    </div>
  )
}
