/* global firebase */
import { CONFIG } from "../config"
import { JOB_STATUS, JOB_TYPE } from "./../const/jobs"
import { time } from "../utils/time"
import { updateServerData } from "./data"
import { triggerWorkflowJob } from "./workflowJobs"

// Prevent users from triggering another deploy < 20 minutes after the first one
// on the same server - shipit cant handle 2 deploy at the same time
// const WP_DEPLOY_TIMEOUT = 20 * 60 // 20 minutes in seconds

let functions = firebase.app().functions(CONFIG.CLOUD_FN_REGION)

// Trigger Firebase Functions deploy fn
async function triggerDeploy(params) {
  console.log(params)
  return functions
    .httpsCallable("triggerDeployGithub")(params)
    .then((result) => {
      console.log(result)
      console.log(`Firebase Function Success: triggerDeploy: `, result.data)
      if (result.data.failed) {
        throw new Error(`Firebase Function Failure: ${result.data.fail_reason}`)
      }
      return result.data
    })
    .catch((error) => {
      console.log(error)
      throw error
    })
}

async function resetServer(serverName) {
  return updateServerData(serverName, {
    ownerUID: "",
    creatorAutoDeploy: false,
    landingAutoDeploy: false,
    note: "",
    creatorDeploymentStatus: JOB_STATUS.IDLE,
    landingDeploymentStatus: JOB_STATUS.IDLE,
    E2EJobStatus: JOB_STATUS.IDLE,
    branch: "master",
    creatorDailyDBReset: false,
  })
}

const deployLandingInstance = async (params) => {
  await updateServerData(params.serverName, {
    landingDeploymentStatus: JOB_STATUS.STARTING,
    landingDeploymentFinishedTimestamp: null,
    landingDeploymentStartedTimestamp: time.getTimeNowInSeconds(),
    landingDeploymentNumber: null,
    landingDeploymentUrl: null,
  })
  triggerWorkflowJob({
    repo: "packhelp/packhelp",
    workflow: "landing-deploy.yml",
    jobType: JOB_TYPE.DEPLOY_LANDING_K8S,
    gitRef: params.branch,
    env: params.serverName,
    triggeredByUID: params.triggeredByUID,
    options: {
      meta: {
        isHotfix: params.isHotfix,
      },
      cbOnFail: async () => {
        await updateServerData(params.serverName, {
          landingDeploymentStatus: JOB_STATUS.ERROR,
          landingDeploymentFinishedTimestamp: time.getTimeNowInSeconds(),
        })
      },
    },
  })
}

const deployPlatformInstance = async (params) => {
  await updateServerData(params.serverName, {
    platformDeploymentStatus: JOB_STATUS.STARTING,
    platformDeploymentFinishedTimestamp: null,
    platformDeploymentStartedTimestamp: time.getTimeNowInSeconds(),
    platformDeploymentNumber: null,
    platformDeploymentUrl: null,
  })
  triggerWorkflowJob({
    repo: "packhelp/packhelp",
    workflow: "platform-deploy.yml",
    jobType: JOB_TYPE.DEPLOY_PLATFORM_K8S,
    gitRef: params.branch,
    env: params.serverName,
    triggeredByUID: params.triggeredByUID,
    options: {
      meta: {
        isHotfix: params.isHotfix,
      },
      cbOnFail: async () => {
        await updateServerData(params.serverName, {
          platformDeploymentStatus: JOB_STATUS.ERROR,
          platformDeploymentFinishedTimestamp: time.getTimeNowInSeconds(),
        })
      },
    },
  })
}

const deployCreatorInstance = async (params) => {
  // For non-production, we default to downtime (Recreate) deployment
  let withDowntime = "yes"
  if (params.serverName === "production") {
    withDowntime =
      params.migrationsScenario === "BREAKING_MIGRATIONS" ? "yes" : "no"
  }

  await updateServerData(params.serverName, {
    creatorDeploymentStatus: JOB_STATUS.STARTING,
    creatorDeploymentFinishedTimestamp: null,
    creatorDeploymentStartedTimestamp: time.getTimeNowInSeconds(),
    creatorDeploymentNumber: null,
    creatorDeploymentUrl: null,
  })
  triggerWorkflowJob({
    repo: "packhelp/packhelp",
    workflow: "creator-deploy.yml",
    jobType: JOB_TYPE.DEPLOY_CREATOR_K8S,
    gitRef: params.branch,
    env: params.serverName,
    triggeredByUID: params.triggeredByUID,
    options: {
      meta: {
        isHotfix: params.isHotfix,
        migrationsScenario: params.migrationsScenario,
      },
      workflowInputs: {
        with_downtime: withDowntime,
      },
      cbOnFail: async () => {
        await updateServerData(params.serverName, {
          creatorDeploymentStatus: JOB_STATUS.ERROR,
          creatorDeploymentFinishedTimestamp: time.getTimeNowInSeconds(),
        })
      },
    },
  })
}

export {
  triggerDeploy,
  deployLandingInstance,
  deployCreatorInstance,
  deployPlatformInstance,
  resetServer,
}
