import React from "react"
import { useHistory } from "react-router-dom"

import Button from "@material-ui/core/Button"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import MoreHorizIcon from "@material-ui/icons/MoreHoriz"
import Tooltip from "@material-ui/core/Tooltip"

import { session, updateServerData } from "./../data/data"
import { herokuDashUrl } from "./../data/herokuLinks"
import {
  resetServer,
  deployCreatorInstance,
  deployLandingInstance,
  deployPlatformInstance,
} from "./../data/deployActions"
import { destroyDevServer } from "../data/serviceActions"

const ServerCellName = ({
  disabled = false,
  name,
  herokuAppName,
  herokuAppNamePlatform,
  appUrl,
  useDefaultStyle,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const history = useHistory()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const openLink = (view) => {
    window.open(herokuDashUrl(herokuAppName, view), "_blank")
    handleClose()
  }

  const handleReset = () => {
    resetServer(name)
    if (name === "staging") {
      updateServerData(name, { creatorDailyDBReset: true })
    }
    handleClose()
  }

  const handleDestroyInstance = async () => {
    if (["staging", "production"].includes(name)) {
      alert("Cannot destroy production and staging environments!")
      return
    }
    if (
      window.confirm("Are you sure? Full process takes a couple of minutes.")
    ) {
      await updateServerData(name, {
        enabled: false,
      })
      await destroyDevServer({
        serverName: name,
        triggeredByUID: session.get().uid,
      })
    }
    handleClose()
  }

  const handleResetDeploy = () => {
    resetServer(name)
    if (name === "staging") {
      updateServerData(name, { creatorDailyDBReset: true })
    }

    deployCreatorInstance({
      branch: "master",
      herokuAppName,
      appUrl,
      serverName: name,
      triggeredByUID: session.get().uid,
      triggeredManually: true,
    })
    deployLandingInstance({
      branch: "master",
      herokuAppName,
      appUrl,
      serverName: name,
      triggeredByUID: session.get().uid,
      triggeredManually: true,
    })
    deployPlatformInstance({
      branch: "master",
      herokuAppNamePlatform,
      appUrl,
      serverName: name,
      triggeredByUID: session.get().uid,
      triggeredManually: true,
    })

    handleClose()
  }

  const allowReset = name !== "production"
  const allowDestroy = !["staging", "production"].includes(name)
  const variant = disabled ? "outlined" : "contained"

  return (
    <div>
      <ButtonGroup
        color={useDefaultStyle ? "default" : "primary"}
        variant={variant}
        aria-label="split button"
      >
        <Button
          href={appUrl}
          size="small"
          style={{ minWidth: 100 }}
          target="_blank"
          rel="noopener noreferrer"
        >
          {name}
        </Button>
        <Button
          size="small"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreHorizIcon />
        </Button>
      </ButtonGroup>
      {!disabled && (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={openLink.bind(null, "activity")}>
            Activity & Rollbacks
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.push(`/jobs?server=${name}`)
            }}
          >
            Jobs
          </MenuItem>
          <MenuItem onClick={openLink.bind(null, "metrics")}>Metrics</MenuItem>
          <MenuItem onClick={openLink.bind(null, "resources")}>
            Resources
          </MenuItem>
          <MenuItem onClick={openLink.bind(null, "logs")}>Logs</MenuItem>
          <MenuItem onClick={openLink.bind(null, "console")}>Console</MenuItem>
          {allowReset && (
            <Tooltip
              title="Reset everything to clean state and `master` branch."
              aria-label="reset"
            >
              <MenuItem style={{ color: "#c38d55" }} onClick={handleReset}>
                Reset
              </MenuItem>
            </Tooltip>
          )}
          {allowReset && (
            <Tooltip
              title="Caution: this will remove `owner`, reset branch to `master`, and trigger deployment."
              aria-label="reset"
            >
              <MenuItem
                style={{ color: "#c38d55" }}
                onClick={handleResetDeploy}
              >
                Reset & Deploy `master`
              </MenuItem>
            </Tooltip>
          )}
          {allowDestroy && (
            <MenuItem
              style={{ color: "#dc4719" }}
              onClick={handleDestroyInstance}
            >
              Destroy instance..
            </MenuItem>
          )}
        </Menu>
      )}
    </div>
  )
}

export default ServerCellName
