import { makeStyles, Typography } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Checkbox from "@material-ui/core/Checkbox"
import TextField from "@material-ui/core/TextField"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import React from "react"
import {
  E2E_CONCURRENCY_DEFAULT,
  E2E_TAGS_DEFAULT,
  E2E_MAX_DATABASE_AGE_DAYS,
} from "../../const/e2e"
import { JOB_STATUS, PENDING_JOB_STATUSES } from "../../const/jobs"
import { session } from "../../data/data"
import { resetE2EDataOnServer, runE2ETests } from "../../data/testActions"
import { TimeElapsed } from "../Time"
import { time } from "../../utils/time"

const useStyles = makeStyles({
  actionsBar: {
    marginTop: "10px",
  },
  runE2EButton: {
    height: "30px",
    marginRight: "5px",
    display: "inline-block",
  },
  actionButtonsWrapper: {
    display: "inline-block",
  },
  resetButton: {
    marginLeft: "10px",
  },
  spinner: {
    top: "3px",
    position: "relative",
    marginRight: "5px",
  },
  card: {
    "margin-top": "20px",
    minWidth: 275,
    position: "sticky",
  },
  chip: {
    marginTop: "5px",
    marginRight: "5px",
  },
  title: {
    fontSize: 14,
  },
  timeIcon: {
    fontSize: 17,
    position: "relative",
    top: 3,
  },
  status: {
    [JOB_STATUS.IDLE]: {},
    [JOB_STATUS.STARTING]: {
      color: "white",
      backgroundColor: "#ffb000",
    },
    [JOB_STATUS.DEPLOYING]: {
      backgroundColor: "#ffb000",
    },
    [JOB_STATUS.TESTING]: {
      backgroundColor: "#ffb000",
    },
    [JOB_STATUS.IDLE]: {
      backgroundColor: "#ffb000",
    },
    [JOB_STATUS.PASSED]: {
      color: "white",
      backgroundColor: "#28cc5c",
    },
    [JOB_STATUS.FAILED]: {
      color: "white",
      backgroundColor: "#d30f12",
    },
    [JOB_STATUS.ERROR]: {
      color: "white",
      backgroundColor: "#d30f12",
    },
  },
})

export const E2ERun = (props) => {
  const [continuousRun, setContinuousRun] = React.useState(false)
  const [continuousRunUntilPass, setContinuousRunUntilPass] =
    React.useState(false)

  const { server } = props
  const {
    E2EJobFinishedTimestamp,
    E2EJobStartedTimestamp,
    E2EJobStatus,
    branch,
    configuredForFasterE2E,
    herokuAppName,
    name,
    landingLastDatabaseMigrateJobFinishedTimestamp,
    creatorLastDatabaseResetJobFinishedTimestamp,
  } = server
  const classes = useStyles()

  const defaultConcurrency = configuredForFasterE2E
    ? E2E_CONCURRENCY_DEFAULT.staging
    : E2E_CONCURRENCY_DEFAULT.dev
  const [concurrency, setConcurrency] = React.useState(defaultConcurrency)

  const handleResetE2EDataOnServer = () => {
    resetE2EDataOnServer(name)
  }

  const isBranchEmpty = !branch
  // We are updating E2EJobStatus via test runner, so we need to check only partial value of status.
  // This fixes showing elapsed time on e2e job and locks the Run E2E button when test is running.
  // ie instead of E2EJobStatus="Running" we are passing for example E2EJobStatus="Running (12 left)"
  const isE2EPending =
    E2EJobStatus &&
    PENDING_JOB_STATUSES.some((status) => {
      return E2EJobStatus.includes(status)
    })
  // TODO(marcin) disable button if branch is being deployed
  // const isDeploying = ['Triggered', 'Starting', 'Deploying'].includes(status)
  const E2ETestingAllowed = !isE2EPending && !isBranchEmpty

  const isDatabaseOlderThanMaxAge = (timestamp) => {
    const maxAgeInMs = time.daysToMs(E2E_MAX_DATABASE_AGE_DAYS)
    const timestampInMs = timestamp * 1000
    return Date.now() - timestampInMs > maxAgeInMs
  }

  const validateDatabaseAge = (landingDbTimestamp, creatorDbTimestamp) => {
    if (!landingDbTimestamp || !creatorDbTimestamp) {
      return true // At least one database timestamp is missing, so we can't check their age
    }

    const isLandingDbOld = isDatabaseOlderThanMaxAge(landingDbTimestamp)
    const isCreatorDbOld = isDatabaseOlderThanMaxAge(creatorDbTimestamp)

    if (isLandingDbOld || isCreatorDbOld) {
      let dbMessage
      if (isLandingDbOld && isCreatorDbOld) {
        dbMessage = `Both Landing and Creator databases are older than ${E2E_MAX_DATABASE_AGE_DAYS} days.`
      } else if (isLandingDbOld) {
        dbMessage = `The Landing database is older than ${E2E_MAX_DATABASE_AGE_DAYS} days.`
      } else {
        dbMessage = `The Creator database is older than ${E2E_MAX_DATABASE_AGE_DAYS} days.`
      }

      const confirmMessage = `WARNING: ${dbMessage} Running E2E tests on outdated databases may cause unexpected test failures and inconsistencies. Are you sure you want to proceed?`
      return window.confirm(confirmMessage)
    }

    return true // Both databases are less than the maximum age
  }

  const handleE2ETrigger = async ({ version, concurrency }) => {
    if (
      !validateDatabaseAge(
        landingLastDatabaseMigrateJobFinishedTimestamp,
        creatorLastDatabaseResetJobFinishedTimestamp
      )
    ) {
      return
    }
    const user = session.get()
    const currentUserServerSettings = session.getServerSettings(server.name)

    let tags = currentUserServerSettings["tags"].sort()
    // If all tags are selected we are assuming "Default Run"
    let containsAllTags =
      tags.length === E2E_TAGS_DEFAULT.sort().length &&
      tags.every(function (element, index) {
        return element === E2E_TAGS_DEFAULT.sort()[index]
      })
    if (containsAllTags) {
      tags = []
    }
    const testRunData = {
      branch,
      concurrency,
      herokuAppName,
      serverConfiguredForFasterE2E: configuredForFasterE2E,
      serverName: name,
      tags,
      triggeredByEmail: user.email,
      triggeredByUID: user.uid,
      triggeredManually: true,
      version,
    }

    return runE2ETests(testRunData).catch((err) => {
      console.error(err)
      throw new Error(err)
    })
  }

  const handleContinuousRunCheckbox = (event) => {
    setContinuousRun(event.target.checked)
  }

  const handleContinuousRunUntilPassCheckbox = (event) => {
    setContinuousRunUntilPass(event.target.checked)
  }

  if (E2ETestingAllowed && (continuousRun || continuousRunUntilPass)) {
    if (continuousRunUntilPass && E2EJobStatus === JOB_STATUS.PASSED) {
      setContinuousRunUntilPass(false)
    } else {
      handleE2ETrigger({ version: "4", concurrency })
    }
  }

  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.actionsBar}>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              Run parameters
            </Typography>
            <br />
            <TextField
              defaultValue={concurrency}
              onChange={(e) => {
                setConcurrency(e.target.value)
              }}
              id="standard-basic"
              label="Tests concurrency"
            />
          </div>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.actionsBar}>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              Continuous testing
            </Typography>
            <Typography className={classes.title} gutterBottom variant="body1">
              Run, and keep running E2E test pipelines one after another while
              checkbox is selected, and this window is open
            </Typography>
            {!continuousRunUntilPass ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={continuousRun}
                    onChange={handleContinuousRunCheckbox}
                  />
                }
                label="In loop"
              />
            ) : null}
            {!continuousRun ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={continuousRunUntilPass}
                    onChange={handleContinuousRunUntilPassCheckbox}
                  />
                }
                label="In loop until PASSED"
              />
            ) : null}
          </div>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.actionsBar}>
            {E2ETestingAllowed ? (
              <div className={classes.actionButtonsWrapper}>
                <Button
                  className={classes.runE2EButton}
                  size="small"
                  variant="contained"
                  onClick={() =>
                    handleE2ETrigger({
                      version: "5-playwright-rc1",
                      concurrency,
                    })
                  }
                  color="primary"
                >
                  Run E2E
                </Button>
              </div>
            ) : (
              <Button
                className={classes.runE2EButton}
                size="small"
                variant="contained"
                color="secondary"
                disabled
              >
                {E2EJobStatus} (
                <TimeElapsed
                  isClockTicking={isE2EPending}
                  startTimestamp={E2EJobStartedTimestamp}
                  endTimestamp={E2EJobFinishedTimestamp}
                />
                )
              </Button>
            )}
            <Button
              size="small"
              color="primary"
              onClick={handleResetE2EDataOnServer}
              className={classes.resetButton}
            >
              Reset Status
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  )
}
