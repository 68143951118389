import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import CircularProgress from "@material-ui/core/CircularProgress"

const useStylesSmallProgress = makeStyles({
  root: {
    position: "relative",
  },
  top: {
    color: "#c7c7c7",
  },
  bottom: {
    color: "#909090",
    animationDuration: "1000ms",
    position: "absolute",
    left: 0,
  },
})

export function SmallCircularProgress(props) {
  const classes = useStylesSmallProgress()

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        value={100}
        className={classes.top}
        size={14}
        thickness={4}
        {...props}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.bottom}
        size={14}
        thickness={4}
        {...props}
      />
    </div>
  )
}
