const PERMISSION = Object.freeze({
  DEPLOY_LANDING: "deploy_landing",
  DEPLOY_CREATOR: "deploy_creator",
  DEPLOY_PLATFORM: "deploy_platform",
  E2E_TAGS_STAGING: "e2e_tags_staging",
})

const DEFAULT_DEPLOY_PERMISSIONS = Object.freeze({
  type: "disallow",
  users: [
    // Everyone has access to production deployments
    // excluding QA team
    "patryk.mirecki@packhelp.com",
    "jakub.woszczek@packhelp.com",
    "tomasz.cierpicki@packhelp.com",
  ],
})

Object.assign(exports, {
  PERMISSION,
  DEFAULT_DEPLOY_PERMISSIONS,
})
