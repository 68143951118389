import React, { useEffect, useState } from "react"
import { getUsers } from "../../data/data"
import Box from "@material-ui/core/Box"
import LinearProgress from "@material-ui/core/LinearProgress"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Divider from "@material-ui/core/Divider"
import { Job } from "./Job"

export const JobsList = ({ jobs, jobsLoading }) => {
  const [users, setUsers] = useState([])
  const [usersLoading, setUsersLoading] = useState(true)

  useEffect(() => {
    getUsers().then((users) => {
      setUsers(users)
      setUsersLoading(false)
    })
  }, [])

  const loading = usersLoading || jobsLoading

  const usersUIDMap = users.reduce((accMap, currentUser) => {
    accMap[currentUser.uid] = currentUser
    return accMap
  }, {})
  const jobsWithUsers = jobs.map((job) =>
    Object.assign({}, job, { user: usersUIDMap[job.triggeredByUID] })
  )

  return loading ? (
    <Box style={{ padding: "30px 0px" }}>
      <LinearProgress />
    </Box>
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper>
          {/* JOB */}
          {jobsWithUsers.map((job, i) => (
            <Box key={job.id}>
              <Job job={job} user={job.user} />
              {jobs.length !== i + 1 && <Divider />}
            </Box>
          ))}
        </Paper>
      </Grid>
    </Grid>
  )
}
