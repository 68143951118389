import React from "react"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import Chip from "@material-ui/core/Chip/Chip"
import EventAvailableIcon from "@material-ui/icons/EventAvailable"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"

import { TimeElapsed } from "../Time"
import { makeStyles } from "@material-ui/core"

import { JOB_STATUS, PENDING_JOB_STATUSES } from "../../const/jobs"
import { time } from "../../utils/time"

const useStyles = makeStyles({
  actionsBar: {
    marginTop: "10px",
  },
  runE2EButton: {
    height: "30px",
    marginRight: "5px",
    display: "inline-block",
  },
  actionButtonsWrapper: {
    display: "inline-block",
  },
  resetButton: {
    marginLeft: "10px",
  },
  spinner: {
    top: "3px",
    position: "relative",
    marginRight: "5px",
  },
  card: {
    "margin-top": "20px",
    minWidth: 275,
    position: "sticky",
  },
  chip: {
    marginTop: "5px",
    marginRight: "5px",
  },
  title: {
    fontSize: 14,
  },
  timeIcon: {
    fontSize: 17,
    position: "relative",
    top: 3,
  },
  status: {
    [JOB_STATUS.IDLE]: {},
    [JOB_STATUS.STARTING]: {
      color: "white",
      backgroundColor: "#ffb000",
    },
    [JOB_STATUS.DEPLOYING]: {
      backgroundColor: "#ffb000",
    },
    [JOB_STATUS.TESTING]: {
      backgroundColor: "#ffb000",
    },
    [JOB_STATUS.IDLE]: {
      backgroundColor: "#ffb000",
    },
    [JOB_STATUS.PASSED]: {
      color: "white",
      backgroundColor: "#28cc5c",
    },
    [JOB_STATUS.FAILED]: {
      color: "white",
      backgroundColor: "#d30f12",
    },
    [JOB_STATUS.ERROR]: {
      color: "white",
      backgroundColor: "#d30f12",
    },
  },
})

function JobStatusPill(props) {
  let { status } = props
  const classes = useStyles()

  return <Chip size="small" label={status} className={classes.status[status]} />
}

export const E2ECard = (props) => {
  const { server } = props
  const {
    E2EJobFinishedTimestamp,
    E2EJobCommit,
    E2EJobBranch,
    E2EJobStatus,
    E2ELastJobStatus,
    E2EJobUrl,
    E2EJobNumber,
    E2EJobStartedTimestamp,
  } = server

  const classes = useStyles()

  const commitURL = () => {
    if (!E2EJobCommit) {
      return ""
    }
    const repoURL = "https://github.com/packhelp/packhelp"
    return `${repoURL}/commit/${E2EJobCommit}`
  }

  const isE2EPending = PENDING_JOB_STATUSES.includes(E2EJobStatus)
  // TODO(marcin) disable button if branch is being deployed
  // const isDeploying = ['Triggered', 'Starting', 'Deploying'].includes(status)
  const statusToDisplay =
    E2EJobStatus === JOB_STATUS.IDLE ? E2ELastJobStatus : E2EJobStatus

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          E2E test run
        </Typography>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableBody>
            <TableRow key="job">
              <TableCell component="th" scope="row">
                CI job
              </TableCell>
              <TableCell>
                <a href={E2EJobUrl} target="_blank" rel="noopener noreferrer">
                  {E2EJobNumber}
                </a>
              </TableCell>
            </TableRow>
            <TableRow key="time">
              <TableCell component="th" scope="row">
                Time
              </TableCell>
              <TableCell>
                {isE2EPending ? (
                  <TimeElapsed
                    isClockTicking={isE2EPending}
                    startTimestamp={E2EJobStartedTimestamp}
                    endTimestamp={E2EJobFinishedTimestamp}
                  />
                ) : (
                  <div>
                    <EventAvailableIcon className={classes.timeIcon} />{" "}
                    {time.timestampToFormattedDate(E2EJobFinishedTimestamp)}
                  </div>
                )}
              </TableCell>
            </TableRow>
            <TableRow key="status">
              <TableCell component="th" scope="row">
                Status
              </TableCell>
              <TableCell>
                <JobStatusPill status={statusToDisplay} />
              </TableCell>
            </TableRow>
            <TableRow key="branch">
              <TableCell component="th" scope="row">
                Branch
              </TableCell>
              <TableCell>{E2EJobBranch}</TableCell>
            </TableRow>
            <TableRow key="commit">
              <TableCell component="th" scope="row">
                Commit
              </TableCell>
              <TableCell>
                <a href={commitURL()} target="_blank" rel="noopener noreferrer">
                  {E2EJobCommit}
                </a>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}
