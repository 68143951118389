import React from "react"
import Paper from "@material-ui/core/Paper"
import ServersList from "../../components/ServersList"
import { ServersListHeading } from "../../components/ServersListHeading"

export const HighlightedServers = (props) => {
  const { servers } = props

  return (
    <>
      <ServersListHeading title="Special Servers" />
      <Paper style={{ margin: "10px 0 15px 0" }}>
        <ServersList servers={servers.special} hideTH={true} />
      </Paper>

      <ServersListHeading title="Staging" />
      <Paper style={{ margin: "10px 0 15px 0" }}>
        <ServersList servers={servers.staging} hideTH={true} />
      </Paper>

      <ServersListHeading title="Production" />
      <Paper style={{ margin: "10px 0 15px 0" }}>
        <ServersList servers={servers.production} hideTH={true} />
      </Paper>
    </>
  )
}
