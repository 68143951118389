import React from "react"
import { useStyles } from "./use-styles"
import Grid from "@material-ui/core/Grid"
import Avatar from "@material-ui/core/Avatar"
import Box from "@material-ui/core/Box"
import { Typography } from "@material-ui/core"
import Chip from "@material-ui/core/Chip"
import { JOB_RUNNER, JOB_TYPE, JOB_TYPE_HUMAN_READABLE } from "../../const/jobs"
import GitHubIcon from "@material-ui/icons/GitHub"
import capitalize from "lodash/capitalize"
import { time } from "../../utils/time"
import Link from "@material-ui/core/Link"
import { JobStatus } from "./JobStatus"
import { JobElapsed } from "./JobElapsed"

export const Job = ({ job, user }) => {
  const classes = useStyles()

  const jobStarted = time.timestampToFormattedDate(
    time.firebaseTsToSeconds(job.startedTimestamp)
  )
  const jobFinished = time.timestampToFormattedDate(
    time.firebaseTsToSeconds(job.finishedTimestamp)
  )
  const jobTiming = `${jobStarted} → ${jobFinished || "…"}`

  return (
    <Grid container className={classes.jobRow}>
      <Grid item container xs={8}>
        <Grid item>
          {user ? (
            <Avatar alt={user.displayName} src={user.photoURL} />
          ) : (
            <Avatar alt="Automatic action" src="robot-emoji-icon-120x120.png" />
          )}
        </Grid>
        <Grid item className={classes.jobTextDataBlock}>
          <Box>
            {user ? (
              <Box component="span">
                <Typography
                  variant="body2"
                  component="span"
                  className={classes.emphText}
                >
                  {user.displayName}
                </Typography>
                <Typography variant="body2" component="span">
                  {" "}
                  created a job:
                </Typography>
              </Box>
            ) : (
              <Typography variant="body2" component="span">
                A job was created:
              </Typography>
            )}
            <Chip
              className={classes.smallChipUppercase}
              variant="outlined"
              size="small"
              label={JOB_TYPE_HUMAN_READABLE[job.type]}
            />
            <Typography variant="body2" component="span">
              on
            </Typography>
            <Chip
              className={classes.smallChipUppercase}
              variant="outlined"
              size="small"
              label={job.server}
            />
            {job.runner && (
              <span>
                <Typography variant="body2" component="span">
                  with runner
                </Typography>
                {job.runner === JOB_RUNNER.GITHUB ? (
                  <Chip
                    className={classes.smallChipUppercase}
                    icon={<GitHubIcon />}
                    variant="outlined"
                    size="small"
                    label={capitalize(job.runner)}
                  />
                ) : (
                  <Chip
                    className={classes.smallChipUppercase}
                    variant="outlined"
                    size="small"
                    label={capitalize(job.runner)}
                  />
                )}
              </span>
            )}
          </Box>
          <Box>
            <Typography variant="caption">
              <span className={classes.textBlock}>{jobTiming}</span>
              <span className={classes.textBlock}>
                <span className={classes.gitBranch}>{job.branch}</span>{" "}
                {job.commitSHA && (
                  <span>
                    (
                    <Link variant="caption">
                      {/* 6afe901d */}
                      {job.commitSHA}
                    </Link>
                    )
                  </span>
                )}
              </span>
              {job.url && (
                <span className={classes.textBlock}>
                  <Link
                    href={job.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="caption"
                  >
                    View on CI/CD
                  </Link>
                </span>
              )}
              {job.externalID && job.type === JOB_TYPE.TEST_E2E && (
                <span className={classes.textBlock}>
                  <Link
                    href={`https://eye.packhelp.com/app/1/${job.externalID}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="caption"
                  >
                    View Eye Report
                  </Link>
                </span>
              )}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid item container xs={4} justify="flex-end">
        <JobElapsed job={job} />
        <JobStatus status={job.status} jobURL={job.url} />
      </Grid>
    </Grid>
  )
}
