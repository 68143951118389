const HEROKU_DASHBOARD_URL = "https://dashboard.heroku.com/"

const herokuDashUrl = (appName, view) => {
  const base = `${HEROKU_DASHBOARD_URL}apps/${appName}/`
  if (view === "console") {
    return `${base}?web-console=${appName}`
  }
  return `${base}${view}`
}

export { herokuDashUrl }
