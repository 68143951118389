import React from "react"
import { ServersSection } from "../../components/ServersSection"

export const FavoriteServers = (props) => {
  const { servers } = props

  return (
    <ServersSection
      isInitiallyExpanded
      servers={servers}
      showCount
      title="Favorite"
    />
  )
}
