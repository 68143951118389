import React from "react"

import FormControl from "@material-ui/core/FormControl"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"

import { JOB_TYPE, JOB_TYPE_HUMAN_READABLE } from "../const/jobs"

const JobTypeSelect = ({ type, handleChange }) => {
  const jobTypes = Object.values(JOB_TYPE)

  return (
    <FormControl className="user-select-form-control">
      <Select
        value={type}
        onChange={handleChange}
        className="user-select-root"
        displayEmpty
      >
        <MenuItem className="user-select-item" value="">
          <span>Type / All Jobs</span>
        </MenuItem>

        {jobTypes.map((type) => (
          <MenuItem key={type} className="user-select-item" value={type}>
            <span>{JOB_TYPE_HUMAN_READABLE[type]}</span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default JobTypeSelect
