import React from "react"
import remove from "lodash/remove"

import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Typography from "@material-ui/core/Typography"

import {
  session,
  updateServerData,
  serverLocalStateManager,
} from "../data/data"
import UserSelect from "./UserSelect"
import { DeploymentCard } from "../views/DeploymentCard"
import RunE2EModal from "../views/E2EModal/RunE2EModal"
import FavoriteToggle from "./FavoriteToggle"
import ServerEditableCellNote from "./ServerEditableCellNote"
import ServerEditableCellBranch from "./ServerEditableCellBranch"
import ServerCellName from "./ServerCellName"

export const ServerRow = React.memo(({ server }) => {
  const {
    appUrl,
    branch,
    enabled,
    herokuAppName,
    isFavorite,
    name,
    note,
    ownerUID,
    creatorDailyDBReset,
  } = server
  const isProduction = Boolean(server.production)

  const [runE2EModalOpen, setRunE2EModalOpen] = React.useState(false)
  const [isExpanded, setIsExpanded] = React.useState(isFavorite || isProduction)

  const isOwnerSet = Boolean(ownerUID)
  const displayFavorite = !["staging", "production"].includes(name)

  const updateRemoteData = (dataUpdate) => {
    updateServerData(name, dataUpdate)
  }

  const toggleServerFavorite = (serverName) => {
    const favoriteServers = session.getSettings().favoriteServers
    const isFavorite = favoriteServers.includes(serverName)
    if (isFavorite) {
      remove(favoriteServers, (name) => name === serverName)
    } else {
      favoriteServers.push(serverName)
    }
    serverLocalStateManager.propagate(serverName, { isFavorite: !isFavorite })
    session.updateSettings({ favoriteServers })
  }

  const handleOwnerChange = (event) => {
    const nextOwnerUID = event.target.value
    updateServerData(name, { ownerUID: nextOwnerUID })
    const isOwnedByCurrentUser = ownerUID === session.get().uid
    const willBeOwnedByCurrentUser = nextOwnerUID === session.get().uid
    if (!isOwnedByCurrentUser && willBeOwnedByCurrentUser) {
      if (!isFavorite) {
        toggleServerFavorite(server.name)
      }
    }
    if (isOwnedByCurrentUser && !willBeOwnedByCurrentUser) {
      if (isFavorite) {
        toggleServerFavorite(server.name)
      }
    }
  }

  const handleExpandToggle = () => {
    setIsExpanded(!isExpanded)
  }

  const openRunE2EModal = () => {
    setRunE2EModalOpen(true)
  }

  const onFavoriteChange = () => {
    toggleServerFavorite(server.name)
  }

  return (
    <div style={{ width: "100%" }}>
      <RunE2EModal
        server={server}
        runE2EModalOpen={runE2EModalOpen}
        setRunE2EModalOpen={setRunE2EModalOpen}
      />
      <Table key={name}>
        <colgroup>
          <col style={{ width: "3%" }} />
          <col style={{ width: "10%" }} />
          <col style={{ width: "37%" }} />
          <col style={{ width: "50%" }} />
        </colgroup>
        <TableBody>
          {enabled ? (
            <TableRow>
              <TableCell style={{ minWidth: "50px" }}>
                {displayFavorite && (
                  <FavoriteToggle
                    onClick={onFavoriteChange}
                    pinned={isFavorite}
                  ></FavoriteToggle>
                )}
              </TableCell>
              <TableCell component="th" style={{ paddingTop: 15 }}>
                {isProduction ? (
                  <ServerCellName
                    server={server}
                    name={name}
                    useDefaultStyle={!isProduction}
                    herokuAppName={herokuAppName}
                    appUrl={appUrl}
                  />
                ) : (
                  <ServerCellName
                    server={server}
                    name={name}
                    useDefaultStyle={!isOwnerSet}
                    herokuAppName={herokuAppName}
                    appUrl={appUrl}
                  />
                )}
                {!isProduction && (
                  <UserSelect
                    ownerUID={server.ownerUID}
                    emptyLabel={"Free"}
                    currentUserLabel={"Assign Me"}
                    handleChange={handleOwnerChange}
                  />
                )}
              </TableCell>
              <TableCell style={{ height: "100%", maxWidth: 200 }}>
                <Card
                  style={{
                    minWidth: 250,
                    textAlign: "left",
                    padding: 10,
                    boxShadow: "none",
                  }}
                >
                  <ServerEditableCellBranch
                    initialBranch={branch}
                    onSave={updateRemoteData}
                  />
                  <ServerEditableCellNote
                    initialNote={note}
                    onSave={updateRemoteData}
                  />
                </Card>
              </TableCell>
              <TableCell align="right" style={{ verticalAlign: "top" }}>
                <Button
                  size="small"
                  onClick={handleExpandToggle}
                  style={{ margin: "0 6px 0 0", color: "#888888" }}
                >
                  {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}{" "}
                  Environment Details
                </Button>
                {isExpanded && (
                  <DeploymentCard
                    {...server}
                    openRunE2EModal={openRunE2EModal}
                    creatorDailyDBReset={creatorDailyDBReset}
                  />
                )}
              </TableCell>
            </TableRow>
          ) : (
            <TableRow>
              <TableCell style={{ minWidth: "50px" }}></TableCell>
              <TableCell component="th">
                <ServerCellName
                  server={server}
                  name={name}
                  useDefaultStyle={true}
                  disabled={true}
                  herokuAppName={herokuAppName}
                  branch={branch}
                  appUrl={appUrl}
                />
              </TableCell>
              <TableCell align="center" colSpan={5}>
                <Typography variant="body2" style={{ color: "#9e9e9e" }}>
                  Not available – provisioning or deprovisioning..
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
})

export default ServerRow
