import React, { useEffect, useState } from "react"
import { useQuery } from "../../hooks"
import { db } from "../../data/data"
import Container from "@material-ui/core/Container"
import { Typography } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import ServerSelect from "../../components/ServerSelect"
import UserSelect from "../../components/UserSelect"
import JobTypeSelect from "../../components/JobTypeSelect"
import JobStatusSelect from "../../components/JobStatusSelect"
import TablePagination from "@material-ui/core/TablePagination"
import { JobsList } from "./JobsList"

export const JobsView = () => {
  let query = useQuery()
  const [loading, setLoading] = useState(true)
  const [jobs, setJobs] = useState([])
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [jobsCount, setJobsCount] = useState(1)
  const [lastVisibleOnPage, setLastVisibleOnPage] = useState({})

  const initialServerFilter = query.get("server") || ""
  const [serverFilter, setServerFilter] = useState(initialServerFilter)

  const initialOwnerFilter = query.get("uid") || ""
  const [ownerFilter, setOwnerFilter] = useState(initialOwnerFilter)

  const initialTypeFilter = query.get("type") || ""
  const [typeFilter, setTypeFilter] = useState(initialTypeFilter)

  const initialStatusFilter = query.get("status") || ""
  const [statusFilter, setStatusFilter] = useState(initialStatusFilter)

  useEffect(
    function loadSyncJobs() {
      setLoading(true)
      let query = db
        .collection("jobs")
        .orderBy("startedTimestamp", "desc")
        .limit(perPage)

      if (serverFilter) {
        query = query.where("server", "==", serverFilter)
      }
      if (ownerFilter) {
        query = query.where("triggeredByUID", "==", ownerFilter)
      }

      if (typeFilter) {
        query = query.where("type", "==", typeFilter)
      }

      if (statusFilter) {
        query = query.where("status", "==", statusFilter)
      }

      if (page && lastVisibleOnPage[page - 1]) {
        query = query.startAfter(lastVisibleOnPage[page - 1]).limit(perPage)
      }

      return query.onSnapshot((snapshot) => {
        setLastVisibleOnPage({
          ...lastVisibleOnPage,
          [page]: snapshot.docs[snapshot.docs.length - 1],
        })

        const docs = snapshot.docs.map((doc) =>
          Object.assign(doc.data(), {
            id: doc.id,
          })
        )
        console.log(docs)
        setJobs(docs)
        setLoading(false)
      })
    },
    // eslint-disable-next-line
    [page, perPage, serverFilter, ownerFilter, typeFilter, statusFilter]
  )

  useEffect(() => {
    setPage(0) // Set page to #1 when any filter or perPage has changed
  }, [perPage, serverFilter, ownerFilter, typeFilter, statusFilter])

  useEffect(function getJobsCount() {
    db.collection("counters")
      .doc("jobs")
      .get()
      .then((docSnapshot) => {
        setJobsCount(docSnapshot.data().count)
      })
  }, [])

  const handleChangePage = (event, page) => setPage(page)
  const handleChangeRowsPerPage = (event) => {
    setPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleServerChange = (event) => {
    console.log(event.target.value)
    setServerFilter(event.target.value)
  }
  const handleOwnerChange = (event) => {
    console.log(event.target.value)
    setOwnerFilter(event.target.value)
  }

  const handleTypeChange = (event) => {
    console.log(event.target.value)
    setTypeFilter(event.target.value)
  }

  const handleStatusChange = (event) => {
    console.log(event.target.value)
    setStatusFilter(event.target.value)
  }

  return (
    <Container maxWidth="lg" style={{ padding: "20px 10px 70px 10px" }}>
      <Typography variant="h5" gutterBottom className="heading-separator">
        Jobs List
      </Typography>

      <Grid container spacing={2} style={{ marginBottom: "20px" }}>
        <Grid item xs={12} alignItems="center" container justify="center">
          <Box component="span" style={{ margin: "5px 10px" }}>
            <ServerSelect
              serverName={serverFilter}
              handleChange={handleServerChange}
            />
          </Box>
          <Box component="span" style={{ margin: "5px 10px" }}>
            <UserSelect
              ownerUID={ownerFilter}
              handleChange={handleOwnerChange}
              emptyLabel={"All Users"}
              currentUserLabel={"My Jobs"}
            />
          </Box>
          <Box component="span" style={{ margin: "5px 10px" }}>
            <JobTypeSelect type={typeFilter} handleChange={handleTypeChange} />
          </Box>
          <Box component="span" style={{ margin: "5px 10px" }}>
            <JobStatusSelect
              status={statusFilter}
              handleChange={handleStatusChange}
            />
          </Box>
        </Grid>
      </Grid>
      <JobsList jobs={jobs} jobsLoading={loading} />

      <Grid container direction="column" justify="center" alignItems="center">
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={jobsCount}
          rowsPerPage={perPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
    </Container>
  )
}
