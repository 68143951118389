import React, { useState } from "react"
import concat from "lodash/concat"
import uniq from "lodash/uniq"
import without from "lodash/without"

import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import Paper from "@material-ui/core/Paper"

import ServersList from "./ServersList"
import { ServersListHeadingWithToggle } from "./ServersListHeadingWithToggle"
// import { getUserSettings, updateUserSettings } from "../utils/userSettings"
import { session } from "../data/data"

export const ServersSection = (props) => {
  const { title, servers, showCount } = props
  const isInitiallyExpanded = session
    .getSettings()
    .expandedSections.includes(title)

  const [isExpanded, setExpanded] = useState(isInitiallyExpanded)

  const setExpandedPersistent = (expanded) => {
    const expandedSections = session.getSettings().expandedSections
    const newExpandedSections = expanded
      ? uniq(concat(expandedSections, [title]))
      : without(expandedSections, title)
    session.updateSettings({
      expandedSections: newExpandedSections,
    })
    setExpanded(expanded)
  }

  const sectionTitle = showCount ? `${title} (${servers.length})` : title

  return (
    <Box>
      <ServersListHeadingWithToggle
        title={sectionTitle}
        isExpanded={isExpanded}
        onToggleClick={() => setExpandedPersistent(!isExpanded)}
      />
      {isExpanded ? (
        <Paper style={{ margin: "10px 0 15px 0" }}>
          <ServersList servers={servers} />
        </Paper>
      ) : (
        <Divider />
      )}
    </Box>
  )
}
