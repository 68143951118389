/* global firebase */
import filter from "lodash/filter"
import isEqual from "lodash/isEqual"
import sortBy from "lodash/sortBy"
import { isUserAllowed } from "../validation"

import { CONFIG } from "../config"
import { E2E_TAGS_DEFAULT } from "../const/e2e"

let functions = firebase.app().functions(CONFIG.CLOUD_FN_REGION)
let getLastCommitFromBranchFn = functions.httpsCallable(
  "getLastCommitFromBranch"
)
let getUsersFirebaseFunction = functions.httpsCallable("getLaunchboxUsers")

let db = firebase.firestore()

// Used only once for data population
let addServer = (db, server) => {
  return db
    .collection("servers")
    .doc(server.name)
    .set(server)
    .then((docRef) => {
      console.log(docRef)
    })
    .catch((error) => {
      console.error(error)
    })
}

const userSortFn = (u) => u.displayName.split(" ")[1] // by last name
const memoizedGetUsers = (userSortFn) => {
  let cachedSortedUsers = []
  let promise = null
  return () => {
    if (cachedSortedUsers.length > 0) {
      return Promise.resolve(cachedSortedUsers)
    } else {
      if (!promise) {
        promise = new Promise((resolve) => {
          getUsersFirebaseFunction().then((result) => {
            const filteredUsers = filter(result.data, (user) =>
              isUserAllowed(user)
            )
            cachedSortedUsers = sortBy(filteredUsers, userSortFn)
            resolve(cachedSortedUsers)
          })
        })
      }
      return promise
    }
  }
}
const getUsers = memoizedGetUsers(userSortFn)

const memoizedGetServers = () => {
  let cachedSortedServers = []
  let promise = null
  return () => {
    if (cachedSortedServers.length > 0) {
      return Promise.resolve(cachedSortedServers)
    } else {
      if (!promise) {
        promise = new Promise((resolve) => {
          db.collection("servers")
            .get()
            .then((snapshot) => {
              cachedSortedServers = snapshot.docs.map((doc) => doc.data())
              resolve(cachedSortedServers)
            })
        })
      }
      return promise
    }
  }
}
const getServers = memoizedGetServers()

const memoizedGetPermissions = () => {
  let cachedPermissions = []
  let promise = null
  return () => {
    if (cachedPermissions.length > 0) {
      return Promise.resolve(cachedPermissions)
    } else {
      if (!promise) {
        promise = new Promise((resolve) => {
          db.collection("permissions")
            .get()
            .then((snapshot) => {
              cachedPermissions = snapshot.docs.map((doc) => doc.data())
              resolve(cachedPermissions)
            })
        })
      }
      return promise
    }
  }
}
const getPermissions = memoizedGetPermissions()

let serverLocalStateManager = {
  listenerFns: [],
  listen: function (fn) {
    this.listenerFns.push(fn)
  },
  propagate: function () {
    for (const fn of this.listenerFns) {
      fn(...arguments)
    }
  },
}

const updateServerData = (serverId, dataUpdate) => {
  serverLocalStateManager.propagate(serverId, dataUpdate)
  if (typeof dataUpdate.branch === "string") {
    dataUpdate.branch = dataUpdate.branch.trim()
  }
  return db
    .collection("servers")
    .doc(serverId)
    .update(dataUpdate)
    .then(() => {
      console.log(`Updated server ${serverId}: `, dataUpdate)
    })
}

let session = {
  currentUser: null,
  set: function (user) {
    this.currentUser = user
  },
  get: function () {
    return this.currentUser
  },
  getPermissions: function () {
    return this.currentUser.permissions
  },
  setPermissions: function (permissions) {
    const email = this.currentUser.email
    if (!this.currentUser.permissions) {
      this.currentUser.permissions = []
    }
    for (const permission of permissions) {
      const userInList = permission.users.includes(email)
      const shouldAddPermission =
        (permission.type === "disallow" && !userInList) ||
        (permission.type === "allow" && userInList)
      if (shouldAddPermission) {
        this.currentUser.permissions.push(permission.name)
      }
    }
    return this.currentUser.permissions
  },
  hasPermission: function (permissionName) {
    if (!this.currentUser.permissions || !this.currentUser.permissions.length) {
      return false
    }
    return this.currentUser.permissions.includes(permissionName)
  },
  hasPermissions: function (permissionNames) {
    for (const permissionName of permissionNames) {
      if (!this.hasPermission(permissionName)) {
        return false
      }
    }
    return true
  },
  updateSettings: function (update) {
    const settings = this.getSettings()
    this.setSettings(Object.assign(settings, update))
  },
  updateServerSettings: function (serverName, update) {
    const allServers = this.getSettings().serverSettings
    const serverCurrentSettings =
      this.getSettings().serverSettings[serverName] || {}
    console.log(serverName, update)
    console.log(allServers, serverCurrentSettings)
    this.updateSettings({
      serverSettings: {
        ...allServers,
        [serverName]: Object.assign(serverCurrentSettings, update),
      },
    })
  },
  getServerSettings: function (serverName) {
    const DEFAULT_SERVER_SETTINGS = {
      tags: E2E_TAGS_DEFAULT,
      tagsUpdated: undefined,
    }
    return (
      this.getSettings().serverSettings[serverName] || DEFAULT_SERVER_SETTINGS
    )
  },
  setSettings: function (settings) {
    localStorage.setItem(this.currentUser.uid, JSON.stringify(settings))
  },
  getSettings: function () {
    const DEFEAULT_SETTINGS = {
      favoriteServers: [],
      expandedSections: ["Favorite", "My servers"],
      serverSettings: {},
    }

    const settingsString = localStorage.getItem(this.currentUser.uid)

    if (!settingsString) {
      return DEFEAULT_SETTINGS
    }

    const settings = JSON.parse(settingsString)

    const settingsHaveAllKeys = isEqual(
      sortBy(Object.keys(DEFEAULT_SETTINGS)),
      sortBy(Object.keys(settings))
    )

    if (!settingsHaveAllKeys) {
      return DEFEAULT_SETTINGS
    }

    return settings
  },
}

const getBranchLastCommit = (branch) => {
  return getLastCommitFromBranchFn(branch).then((result) => result.data)
}

window.session = session

export {
  session,
  addServer,
  updateServerData,
  getUsers,
  getServers,
  getPermissions,
  db,
  serverLocalStateManager,
  getBranchLastCommit,
}
