import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  box: {
    border: "1px dotted #666",
  },
  jobRow: {
    padding: "10px 20px",
  },
  jobTextDataBlock: {
    padding: "0 0 0 10px",
  },
  emphText: {
    fontWeight: 500,
  },
  smallChipUppercase: {
    margin: "0 5px",
    fontSize: "11px",
    fontWeight: 600,
    textTransform: "uppercase",
  },
  textBlock: {
    margin: "0 10px 0 0",
  },
  gitBranch: {
    fontWeight: 600,
    color: "#848484",
  },
  timeElapsed: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "13px",
    alignItems: "center",
    display: "flex",
    height: "32px",
    borderRadius: "20px",
    padding: "0 10px",
    margin: "0 10px 0 0",
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
}))
