import React from "react"

import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import LinearProgress from "@material-ui/core/LinearProgress"

const LoaderWithPadding = () => (
  <Container maxWidth="xl">
    <Box style={{ padding: "30px 0px" }}>
      <LinearProgress />
    </Box>
  </Container>
)

export default LoaderWithPadding
