import React from "react"
import Typography from "@material-ui/core/Typography"

export const ServersListHeading = (props) => {
  const { title } = props
  return (
    <div style={{ display: "grid", gridTemplateColumns: "1fr 8fr 2fr" }}>
      <div style={{ width: "155px" }} />
      <Typography variant="h5" gutterBottom className="heading-separator">
        {title}{" "}
      </Typography>
    </div>
  )
}
