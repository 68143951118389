import React, { useEffect } from "react"

import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Badge from "@material-ui/core/Badge"
import Checkbox from "@material-ui/core/Checkbox"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormGroup from "@material-ui/core/FormGroup"
import Tooltip from "@material-ui/core/Tooltip"
import { E2E_TAGS_DEFAULT, E2E_TAGS_SPECIAL } from "../../const/e2e"
import { makeStyles } from "@material-ui/core"
import { session } from "../../data/data"
import { time } from "../../utils/time"
import { withStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  card: {
    "margin-top": "20px",
    minWidth: 275,
    position: "sticky",
  },
})

const StyledBadge = withStyles(() => ({
  badge: {
    border: `2px solid red`,
    backgroundColor: "red",
    color: "white",
    top: -5,
    right: "auto",
    left: -50,
    "-webkit-transform": "rotate(-35deg)",
    transform: "rotate(-35deg)",
  },
}))(Badge)

export const E2ETags = (props) => {
  const { server, enabled } = props
  const sortedE2EDefaultTags = E2E_TAGS_DEFAULT.sort()
  const e2eExtendedTags = E2E_TAGS_SPECIAL
  const tooltipMessage = `You can select tags you want to run (i.e only tests for landing). It may drasticly reduce test time. If no tags are selected it defaults to normal E2E run.`
  const extendedTestTooltipMessage = `This test may take very long time. It is not a part of the default run`
  const getTagsSettings = () => {
    let settings = session.getServerSettings(server.name)
    const tagsUpdated = settings.tagsUpdated
    const haveTagsSettingsExpired =
      tagsUpdated && time.isOlderThan(tagsUpdated, time.ONE_WEEK)
    if (haveTagsSettingsExpired) {
      session.updateServerSettings(server.name, {
        tags: [],
        tagsUpdated: undefined,
      })
      return []
    }
    return settings.tags
  }

  const [expanded, setExpanded] = React.useState(false)
  const [selectedTags, setSelected] = React.useState(getTagsSettings)
  console.log(selectedTags)

  useEffect(() => {
    session.updateServerSettings(server.name, {
      tags: selectedTags,
      tagsUpdated: Date.now(),
    })
  })

  const classes = useStyles()

  const handleExpansion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleCheckbox = (key) => () => {
    selectedTags.includes(key)
      ? setSelected(selectedTags.filter((item) => item !== key))
      : setSelected([...selectedTags, key])
  }

  const handleDefaultCheckbox = () => {
    if (selectedTags.length === sortedE2EDefaultTags.length) {
      setSelected([])
    } else {
      setSelected(sortedE2EDefaultTags)
    }
  }
  if (!enabled) return null
  return (
    <StyledBadge
      badgeContent={
        <Tooltip title={tooltipMessage} color="primary">
          <div> New feature! </div>
        </Tooltip>
      }
    >
      <Accordion
        className={classes.card}
        onChange={handleExpansion("panel1")}
        expanded={expanded === "panel1"}
        color="primary"
        // anchorOrigin={{
        //   vertical: "top",
        //   horizontal: "left",
        // }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <FormControlLabel
            aria-label="Acknowledge"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={
              <Checkbox
                checked={selectedTags.length === sortedE2EDefaultTags.length}
                onChange={handleDefaultCheckbox}
              />
            }
            label={<div>Default run </div>}
          ></FormControlLabel>
        </AccordionSummary>

        <AccordionDetails>
          <FormGroup row>
            {sortedE2EDefaultTags.map(function (key) {
              return (
                <div key={key}>
                  <FormControlLabel
                    key={key}
                    control={
                      <Checkbox
                        checked={selectedTags.includes(key)}
                        onChange={handleCheckbox(key)}
                      />
                    }
                    label={key}
                  />
                </div>
              )
            })}

            {e2eExtendedTags.map(function (key) {
              return (
                <div key={key}>
                  <FormControlLabel
                    key={key}
                    control={
                      <Checkbox
                        checked={selectedTags.includes(key)}
                        disabled={false}
                        onChange={handleCheckbox(key)}
                      />
                    }
                    label={
                      <span>
                        <Tooltip title={extendedTestTooltipMessage}>
                          <span role="img" aria-label="hourglass-emoji">
                            ⌛
                          </span>
                        </Tooltip>
                        <span>{key}</span>
                      </span>
                    }
                  />
                </div>
              )
            })}
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    </StyledBadge>
  )
}
