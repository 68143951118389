import React, { useState, useEffect } from "react"

import Avatar from "@material-ui/core/Avatar"
import Divider from "@material-ui/core/Divider"
import FormControl from "@material-ui/core/FormControl"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"

import { session, getUsers } from "./../data/data"

const UserSelect = ({
  ownerUID,
  handleChange,
  emptyLabel,
  currentUserLabel,
}) => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getUsers().then((users) => {
      setUsers(users)
      setLoading(false)
    })
  }, [])
  const currentUser = session.get()

  if (loading) {
    return (
      <FormControl disabled className="user-select-form-control">
        <Select displayEmpty value="" className="user-select-root">
          <MenuItem value="">
            <em>Loading..</em>
          </MenuItem>
        </Select>
      </FormControl>
    )
  }

  return (
    <FormControl className="user-select-form-control">
      <Select
        value={ownerUID}
        onChange={handleChange}
        className="user-select-root"
        displayEmpty
      >
        <MenuItem className="user-select-item" value="">
          <span>{emptyLabel}</span>
        </MenuItem>

        <MenuItem
          key={currentUser.uid}
          value={currentUser.uid}
          className="user-select-item"
        >
          <Avatar alt={currentUser.displayName} src={currentUser.photoURL} />
          <span>{currentUserLabel}</span>
        </MenuItem>

        <Divider />

        {users.map((user) => (
          <MenuItem
            key={user.uid}
            className="user-select-item"
            value={user.uid}
          >
            <Avatar alt={user.displayName} src={user.photoURL} />
            <span>{user.displayName}</span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default UserSelect
