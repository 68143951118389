import React from "react"
import { useEffect } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Link from "@material-ui/core/Link"
import Typography from "@material-ui/core/Typography"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import ActionPurgeCloudflareCache from "./ActionPurgeCloudflareCache"
import ActionPurgeVarnishCache from "./ActionPurgeVarnishCache"

import { CardActions } from "@material-ui/core"

export default function ClearCacheDialog({ open, setOpen }) {
  // Clear values on closing the modal
  useEffect(() => {}, [open])

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {`⚠️ Landing Cache Clear Service: Production `}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                <b>CloudFlare Cache</b>
              </Typography>
              <Typography gutterBottom variant="body2">
                Cloudflare Cache is in front of Varnish and Backend website and
                acts as a cache for non-html assets.
              </Typography>
            </CardContent>
            <CardActions>
              <ActionPurgeCloudflareCache />
            </CardActions>
          </Card>
          <Card>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                <b>Varnish Cache</b>
              </Typography>
              <Typography gutterBottom variant="body2">
                Varnish Cache sits between Cloudflare and our Wordpress Backend.
              </Typography>
              <Link
                href="https://www.notion.so/packhelp/DevOps-Four-Key-Metrics-a20ab82d49f54c8e92f433fb95b69a65"
                target="_blank"
                rel="noopener noreferrer"
                variant="body2"
              >
                Read documentation here.
              </Link>
              <Typography gutterBottom variant="body2">
                <b>
                  IMPORTANT: If you're introducing a breaking change (backwards
                  incompatible) to any API that landing is dependent on (Rails
                  API, etc.), please note that some users might have the old JS
                  bundle running, requesting no longer available endpoints,
                  resulting in errors. This behaviour will persist for TTL time
                  (e.g. 1 hour). For example, changing endpoint /account to
                  /userAccount` will result in such errors. If you must do such
                  changes, clear Varnish cache after changing the dependent
                  API(s).
                </b>
              </Typography>
            </CardContent>
            <CardActions>
              <ActionPurgeVarnishCache></ActionPurgeVarnishCache>
            </CardActions>
          </Card>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
