import React from "react"

import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import ErrorIcon from "@material-ui/icons/Error"
import { JOB_STATUS, PENDING_JOB_STATUSES } from "../const/jobs"
import Box from "@material-ui/core/Box"
import { Typography, Button, Tooltip } from "@material-ui/core"
import HelpIcon from "@material-ui/icons/Help"
import { time } from "../utils/time"
import { TimeElapsed } from "./../views/Time"

const ActionRow = ({
  name,
  show = true,
  buttonLabel,
  lastDate,
  lastStatus,
  lastActionStartedTimestamp,
  lastActionFinishedTimestamp,
  actionStartedTimestamp,
  lastUrl,
  disabled,
  onButtonClick,
  jobStatus,
  tooltipTitle = "No tooltip",
}) => {
  const isPending = PENDING_JOB_STATUSES.includes(jobStatus)

  const jobDuration = time.timeInSecondsToFormattedTime(
    lastActionFinishedTimestamp - lastActionStartedTimestamp
  )

  const handleButtonClick = () => {
    onButtonClick()
  }
  if (!show) {
    return null
  }
  return (
    <Box className="app-state-row" style={{ marginLeft: "0px" }}>
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Box
            style={{ display: "flex", alignItems: "center", minWidth: "135px" }}
          >
            <Typography style={{ fontSize: "0.875rem", marginRight: "10px" }}>
              {name}
            </Typography>
            <Tooltip title={tooltipTitle} style={{ marginLeft: "10px" }}>
              <HelpIcon style={{ color: "#aaa", fontSize: "0.875rem" }} />
            </Tooltip>
          </Box>
          <span style={{ marginLeft: "10px" }}>
            {(lastUrl && (
              <a href={lastUrl} target="_blank" rel="noopener noreferrer">
                <span>{time.timestampToFormattedDate(lastDate)}</span>
              </a>
            )) || <span>{time.timestampToFormattedDate(lastDate)}</span>}
          </span>
          <span style={{ marginLeft: "10px" }}>
            {lastStatus === JOB_STATUS.ERROR ? (
              <ErrorIcon
                style={{
                  height: 17,
                  position: "relative",
                  top: 4,
                  color: "#e6543c",
                }}
              />
            ) : (
              <CheckCircleIcon
                style={{
                  height: 17,
                  position: "relative",
                  top: 4,
                  color: "#28cc5c",
                }}
              />
            )}
          </span>
          <span style={{ marginLeft: "10px" }}>
            <span style={{ fontSize: "15px" }}>⏱</span> {jobDuration}
          </span>
        </Box>

        <Box style={{ display: "flex" }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{
              minWidth: "160px",
              maxHeight: "25px",
              lineHeight: "16px",
              fontSize: "11px",
            }}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleButtonClick.bind(this)}
            disabled={disabled}
          >
            {!isPending ? (
              <span>{buttonLabel || "RUN"}</span>
            ) : (
              <TimeElapsed
                isClockTicking={isPending}
                startTimestamp={actionStartedTimestamp}
                endTimestamp={lastActionFinishedTimestamp}
              />
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ActionRow
