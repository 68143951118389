import React, { useState, useEffect } from "react"

import { usePopupState, bindTrigger } from "material-ui-popup-state/hooks"
import { NoteAdd as NoteAddIcon } from "@material-ui/icons"
import { Typography, Box, IconButton } from "@material-ui/core"

import EditPopover from "./EditPopover"

function ServerEditableCellNote({ initialNote, onSave }) {
  const [note, setNote] = useState(initialNote)
  const popupState = usePopupState({
    variant: "popover",
    popupId: "noteDropdown",
  })

  useEffect(() => {
    setNote(initialNote)
  }, [initialNote])

  const noteTrimmed = note.trim()

  return (
    <Box style={{ paddingTop: 10 }}>
      <a {...bindTrigger(popupState)}>
        {noteTrimmed ? (
          <Typography
            variant="body2"
            style={{ overflowWrap: "break-word", cursor: "pointer" }}
          >
            {note}
          </Typography>
        ) : (
          <IconButton
            className="mui--icon-text-button"
            size="small"
            style={{ fontSize: 12, color: "darkgray" }}
          >
            <NoteAddIcon fontSize="small" style={{ marginRight: 5 }} /> Add note
          </IconButton>
        )}
      </a>

      <EditPopover
        state={popupState}
        onClose={() => {
          popupState.close()
          onSave({ note: noteTrimmed })
          setNote(noteTrimmed)
        }}
        label="Note"
        value={note}
        onChange={(e) => {
          setNote(e.target.value)
        }}
        multiline
        rowsMax="4"
      />
    </Box>
  )
}

export default ServerEditableCellNote
