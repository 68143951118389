import React from "react"
import Button from "@material-ui/core/Button"
// import Chip from "@material-ui/core/Chip"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
// import Typography from "@material-ui/core/Typography"

import CommitCard from "../E2EModal/CommitCard"
import { E2ECard } from "./E2ECard"
import { E2ETags } from "./E2ETags"
import { E2ERun } from "./E2ERun"
import { AppsStateCard } from "../E2EModal/AppsStateCard"
import { session } from "../../data/data"
import { PERMISSION } from "../../const/permission"
import { ViewModelServer } from "../../data/view-model.server"

export default function RunE2EModal(props) {
  const { server, runE2EModalOpen, setRunE2EModalOpen } = props

  const handleClose = () => {
    setRunE2EModalOpen(false)
  }

  const canUserUseE2ETags =
    ViewModelServer.isDev(server) ||
    session.hasPermission(PERMISSION.E2E_TAGS_STAGING)
  return (
    <Dialog
      onClose={handleClose}
      open={runE2EModalOpen}
      aria-labelledby="form-dialog-title"
    >
      <div style={{ minWidth: 550 }}>
        <DialogTitle id="form-dialog-title">
          Trigger E2E tests on{" "}
          <span style={{ color: "#3d6dfe" }}>{server.name}</span>
        </DialogTitle>

        <DialogContent>
          <CommitCard server={server} />
          <AppsStateCard server={server} />
          <E2ECard server={server} />
          <E2ETags server={server} enabled={canUserUseE2ETags} />
          <E2ERun server={server} />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}
