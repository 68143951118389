import React from "react"
import { useStyles } from "./use-styles"
import { TimeElapsed } from "../Time"
import { time } from "../../utils/time"

export const JobElapsed = ({ job }) => {
  const isClockTicking = !Boolean(job.finishedTimestamp)

  const classes = useStyles()
  return (
    <div
      className={classes.timeElapsed}
      style={{ color: isClockTicking ? "#bf8600" : "#797979" }}
    >
      <TimeElapsed
        isClockTicking={!Boolean(job.finishedTimestamp)}
        startTimestamp={time.firebaseTsToSeconds(job.startedTimestamp)}
        endTimestamp={time.firebaseTsToSeconds(job.finishedTimestamp)}
        timeout={false}
      />
    </div>
  )
}
