import Typography from "@material-ui/core/Typography"
import React from "react"

const Logo = ({ variant, src }) => (
  <Typography variant={variant} className="logo" gutterBottom>
    <img alt="Logo" src={src} />
    <span>LaunchBox </span>
  </Typography>
)

export default Logo
