/* global firebase */
import { CONFIG } from "../config"
import { JOB_STATUS, JOB_TYPE } from "./../const/jobs"
import { time } from "../utils/time"
import { updateServerData } from "./data"
import { triggerWorkflowJob } from "./workflowJobs"

let functions = firebase.app().functions(CONFIG.CLOUD_FN_REGION)

const clearCloudflareCache = () => {
  return functions
    .httpsCallable("purgeCloudflareCacheCall")()
    .then((result) => {
      console.log(`Firebase Function Success: triggerAction: `, result.data)
      if (result.data.failed) {
        throw new Error(`Firebase Function Failure: ${result.data.fail_reason}`)
      }
      return result.data
    })
    .catch((error) => {
      console.log(error)
      throw error
    })
}

async function creatorResetDatabase({
  env,
  gitRef,
  source,
  snapshotProvider,
  triggeredByUID,
}) {
  await updateServerData(env, {
    creatorDatabaseResetJobStatus: JOB_STATUS.STARTING,
    creatorDatabaseResetJobFinishedTimestamp: null,
    creatorDatabaseResetJobStartedTimestamp: time.getTimeNowInSeconds(),
    creatorDatabaseResetJobNumber: null,
    creatorDatabaseResetJobUrl: null,
  })
  let jobType
  if (source === "dev") {
    jobType = JOB_TYPE.RESET_CREATOR_DB_DEV_K8S
  } else if (source === "production") {
    jobType = JOB_TYPE.RESET_CREATOR_DB_PROD_K8S
  }
  triggerWorkflowJob({
    repo: "packhelp/packhelp",
    workflow: "creator-reset-db.yml",
    env,
    gitRef,
    jobType,
    triggeredByUID,
    options: {
      workflowInputs: {
        source_db: source,
      },
      cbOnFail: async () => {
        await updateServerData(env, {
          creatorDatabaseResetJobStatus: JOB_STATUS.ERROR,
          creatorDatabaseResetJobFinishedTimestamp: time.getTimeNowInSeconds(),
        })
      },
    },
  })
}

async function landingMigrateDB({ env, gitRef, triggeredByUID }) {
  await updateServerData(env, {
    landingDatabaseMigrateJobStatus: JOB_STATUS.STARTING,
    landingDatabaseMigrateJobFinishedTimestamp: null,
    landingDatabaseMigrateJobStartedTimestamp: time.getTimeNowInSeconds(),
    landingDatabaseMigrateJobNumber: null,
    landingDatabaseMigrateJobUrl: null,
  })
  triggerWorkflowJob({
    repo: "packhelp/packhelp",
    workflow: "landing-migrate-db.yml",
    env,
    gitRef,
    jobType: JOB_TYPE.MIGRATE_LANDING_DB_DEV_K8S,
    triggeredByUID,
    options: {
      cbOnFail: async () => {
        await updateServerData(env, {
          landingDatabaseMigrateJobStatus: JOB_STATUS.ERROR,
          landingDatabaseMigrateJobFinishedTimestamp:
            time.getTimeNowInSeconds(),
        })
      },
    },
  })
}

async function createDevServer({ serverName, triggeredByUID }) {
  triggerWorkflowJob({
    repo: "packhelp/packops",
    workflow: "create-dev-server.yml",
    env: serverName,
    gitRef: "master",
    jobType: "CREATE_DEV_SERVER",
    triggeredByUID,
  })
}

async function destroyDevServer({ serverName, triggeredByUID }) {
  triggerWorkflowJob({
    repo: "packhelp/packops",
    workflow: "destroy-dev-server.yml",
    env: serverName,
    gitRef: "master",
    jobType: "DESTROY_DEV_SERVER",
    triggeredByUID,
  })
}

export {
  clearCloudflareCache,
  createDevServer,
  creatorResetDatabase,
  destroyDevServer,
  landingMigrateDB,
}
