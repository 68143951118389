import React, { useState, useEffect } from "react"

import { usePopupState, bindTrigger } from "material-ui-popup-state/hooks"
import Box from "@material-ui/core/Box"
import Chip from "@material-ui/core/Chip"
import IconButton from "@material-ui/core/IconButton"
import MergeTypeIcon from "@material-ui/icons/MergeType"
import Typography from "@material-ui/core/Typography"

import EditPopover from "./EditPopover"

function ServerEditableCellBranch({ initialBranch, onSave }) {
  const [branch, setBranch] = useState(initialBranch)
  const popupState = usePopupState({
    variant: "popover",
    popupId: "branchDropdown",
  })

  useEffect(() => {
    setBranch(initialBranch)
  }, [initialBranch])

  return (
    <Box>
      <a {...bindTrigger(popupState)}>
        {branch ? (
          <Chip
            size="small"
            icon={<MergeTypeIcon />}
            label={
              <Typography
                variant="body2"
                style={{ maxWidth: 240, cursor: "pointer" }}
                noWrap
              >
                {branch}
              </Typography>
            }
          />
        ) : (
          <IconButton
            className="mui--icon-text-button"
            size="small"
            style={{ fontSize: 12, color: "darkgray" }}
          >
            <MergeTypeIcon fontSize="small" style={{ marginRight: 5 }} /> Add
            branch
          </IconButton>
        )}
      </a>

      <EditPopover
        state={popupState}
        onClose={() => {
          popupState.close()
          onSave({ branch: branch.trim() })
        }}
        closeOnEnter={true}
        label="Branch"
        value={branch}
        onChange={(e) => {
          setBranch(e.target.value)
        }}
      />
    </Box>
  )
}

export default ServerEditableCellBranch
