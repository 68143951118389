import React from "react"
import { JOB_STATUS_NEW } from "../../const/jobs"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import { SmallCircularProgress } from "../Progress"
import AdjustRoundedIcon from "@material-ui/icons/AdjustRounded"
import ErrorIcon from "@material-ui/icons/Error"
import Chip from "@material-ui/core/Chip"

export const JobStatus = ({ status, jobURL }) => {
  let icon, text

  if (status === JOB_STATUS_NEW.COMPLETED) {
    icon = <CheckCircleIcon style={{ color: "#4ace20" }} />
    text = "Completed"
  }
  if (status === JOB_STATUS_NEW.IN_PROGRESS) {
    icon = (
      <span style={{ padding: "3px 3px 0 3px" }}>
        <SmallCircularProgress size={18} thickness={5} />
      </span>
    )
    text = "In Progress"
  }
  if (status === JOB_STATUS_NEW.CREATED) {
    icon = <AdjustRoundedIcon />
    text = "Created"
  }
  if (status === JOB_STATUS_NEW.FAILED) {
    icon = <ErrorIcon style={{ color: "#de4211" }} />
    text = "Failed"
  }

  return (
    <Chip
      variant="outlined"
      label={text}
      onClick={() => {
        window.open(jobURL, "_blank")
      }}
      deleteIcon={icon}
      onDelete={() => {
        console.log("deleted")
      }}
    />
  )
}
