const DYNO_PRESET = Object.freeze({
  DEFAULT: "DEFAULT",
  HIGH: "HIGH",
  E2E: "E2E",
  E2E_FAST: "E2E_FAST",
  OFF: "OFF",
})

const PRESET = Object.freeze({
  DEFAULT: {
    CREATOR: [
      { type: "web", quantity: 1, size: "Hobby" },
      { type: "worker-multi", quantity: 1, size: "Hobby" },
      { type: "worker-projections", quantity: 1, size: "Hobby" },
    ],
    PLATFORM: [{ type: "web", quantity: 1, size: "Hobby" }],
  },
  HIGH: {
    CREATOR: [
      { type: "web", quantity: 1, size: "Performance-M" },
      { type: "worker-multi", quantity: 1, size: "Standard-1X" },
      { type: "worker-projections", quantity: 1, size: "Standard-1X" },
    ],
    PLATFORM: [{ type: "web", quantity: 1, size: "Hobby" }],
  },
  E2E: {
    CREATOR: [
      { type: "web", quantity: 1, size: "Performance-M" },
      { type: "worker-multi", quantity: 1, size: "Standard-1X" },
      { type: "worker-projections", quantity: 1, size: "Standard-1X" },
    ],
    PLATFORM: [{ type: "web", quantity: 1, size: "Hobby" }],
  },
  E2E_FAST: {
    CREATOR: [
      { type: "web", quantity: 1, size: "Performance-L" },
      { type: "worker-multi", quantity: 1, size: "Standard-1X" },
      { type: "worker-projections", quantity: 1, size: "Standard-1X" },
    ],
    PLATFORM: [{ type: "web", quantity: 1, size: "Hobby" }],
  },
  OFF: {
    CREATOR: [
      { type: "web", quantity: 0, size: "Hobby" },
      { type: "worker-multi", quantity: 0, size: "Hobby" },
      { type: "worker-projections", quantity: 0, size: "Hobby" },
    ],
    PLATFORM: [{ type: "web", quantity: 0, size: "Hobby" }],
  },
})

const CREATOR_ENV_PRESET = Object.freeze({
  DEFAULT: {
    MAX_THREADS: "1",
    WEB_CONCURRENCY: "1",
    RAILS_MAX_THREADS: null,
  },
  HIGH: {
    MAX_THREADS: "3",
    WEB_CONCURRENCY: "2",
    RAILS_MAX_THREADS: null,
  },
  E2E: {
    MAX_THREADS: "3",
    WEB_CONCURRENCY: "2",
    RAILS_MAX_THREADS: null,
  },
  E2E_FAST: {
    MAX_THREADS: "6",
    WEB_CONCURRENCY: "16",
    RAILS_MAX_THREADS: "6",
  },
  OFF: {
    MAX_THREADS: "1",
    WEB_CONCURRENCY: "1",
    RAILS_MAX_THREADS: null,
  },
})

Object.assign(exports, {
  DYNO_PRESET,
  PRESET,
  CREATOR_ENV_PRESET,
})
