import React, { useState } from "react"

import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormControl from "@material-ui/core/FormControl"
import Input from "@material-ui/core/Input"
import InputLabel from "@material-ui/core/InputLabel"
import Typography from "@material-ui/core/Typography"

import { session } from "../data/data"
import { createDevServer } from "../data/serviceActions"

export default function CreateDevServerDialog({ open, setOpen }) {
  const [serverName, setServerName] = useState("")

  const handleClose = () => {
    setOpen(false)
  }

  const validateServerName = (name) => {
    if (
      !name || // not empty
      !/^[a-z]+[a-z0-9-]*$/.test(name) || // lowercase, must start with letter
      // The reason for this is that we build heroku app name from this, e.g.
      // <name>-ph-dev-platform and Heroku accepts 30 characters in total max
      serverName.length > 14 // must be shorter than 14 chars
    ) {
      return false
    }
    return true
  }

  const dialogTitleText = "CREATE NEW DEV SERVER"

  const triggerCreate = async () => {
    if (!validateServerName(serverName)) {
      alert(
        "Server name needs to: be of 14 characters or less, be lowercase, " +
          "start with a letter, do not contain any special characters"
      )
      return
    }

    await createDevServer({
      serverName: serverName,
      triggeredByUID: session.get().uid,
    })
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{dialogTitleText}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel>Server Name</InputLabel>
          <Input
            autoFocus={true}
            value={serverName}
            onChange={(e) => {
              setServerName(e.target.value)
            }}
          />
        </FormControl>
        <Typography variant="body1" display="block" gutterBottom>
          <br />
          Pick a nice name for your new dev server :)
          <br />
          After it's created, please follow steps below:
          <br />
          <br />
          1. Trigger reset DB to DEV to set up clean creator database.
          <br />
          2. Trigger DB migration of landing database.
          <br />
          3. Deploy all three services to `master` or feature branch of your
          choice.
          <br />
          <br />
          <Button
            href="https://www.notion.so/packhelp/Launchbox-ddaaa5c41e9e4e078210172d722e2bbb#5809401eb006406f931916c5c66e99b8"
            target="_blank"
            size="small"
            variant="contained"
            rel="noopener noreferrer"
          >
            For more information, check out Notion tutorial
          </Button>
          <br />
          <br />
          Done! 🙌
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={triggerCreate} variant="contained" color="primary">
          Add dev server
        </Button>
      </DialogActions>
    </Dialog>
  )
}
