import React from "react"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import CreateDevServerDialog from "../CreateDevServerDialog"

export const ServersViewActions = () => {
  const [createServerDialogOpen, setCreateServerDialogOpen] =
    React.useState(false)

  const createDevServer = () => {
    setCreateServerDialogOpen(true)
  }

  return (
    <Container
      maxWidth="lg"
      style={{ padding: "0px 10px 0px 10px", textAlign: "right" }}
    >
      <Box>
        <Button
          variant="contained"
          color="primary"
          onClick={createDevServer}
          style={{ minWidth: 150 }}
          target="_blank"
          rel="noopener noreferrer"
        >
          Add dev server
        </Button>
        <CreateDevServerDialog
          open={createServerDialogOpen}
          setOpen={setCreateServerDialogOpen}
        />
      </Box>
    </Container>
  )
}
