import React, { useState, useEffect } from "react"

import FormControl from "@material-ui/core/FormControl"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"

import { getServers } from "./../data/data"

const ServerSelect = ({ serverName, handleChange }) => {
  const [servers, setServers] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getServers().then((servers) => {
      setServers(servers)
      setLoading(false)
    })
  }, [])

  if (loading) {
    return (
      <FormControl disabled className="user-select-form-control">
        <Select displayEmpty value="" className="user-select-root">
          <MenuItem value="">
            <em>Loading..</em>
          </MenuItem>
        </Select>
      </FormControl>
    )
  }

  return (
    <FormControl className="user-select-form-control">
      <Select
        value={serverName}
        onChange={handleChange}
        className="user-select-root"
        displayEmpty
      >
        <MenuItem className="user-select-item" value="">
          <span>All Servers</span>
        </MenuItem>

        {servers.map((server) => (
          <MenuItem
            key={server.name}
            className="user-select-item"
            value={server.name}
          >
            <span>{server.name.toUpperCase()}</span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ServerSelect
