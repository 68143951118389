// This file contents should include tags from e2e
// e2e-tests/tests/objects/Tags.ts
export const E2E_TAGS_DEFAULT = [
  "mobile",
  "platform",
  "pricing-and-taxes",
  "creator",
  "editor",
  "editorv1",
  "editorv2",
  "pcs",
  "landing",
  "account",
  "wallet",
  "blog",
  "custom-offer",
  "design-showcase",
  "dtp",
  "shop",
  "dby",
  "checkout",
  "get-a-quote",
  "sign-up",
  "catalogue",
  "plus",

  /**
   * warning, this is tag is not in packhelp/e2e tag list
   */
  "visual-regression",
]

export const E2E_TAGS_SPECIAL = [
  "extended",
  //"buy-all-products",
  "unlock-all-wallet-levels",
]

// TODO: increase when tests are more predictable (~PIM staging faster)
export const E2E_CONCURRENCY_DEFAULT = {
  staging: 10, // was 40 before ~07/2024
  dev: 10, // was 25 before ~07/2024
}

export const E2E_MAX_DATABASE_AGE_DAYS = 7
