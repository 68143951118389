const ONE_WEEK = 24 * 7
const getTimeNowInSeconds = () => {
  return Math.floor(Date.now() / 1000)
}

// Turn UNIX timestamp into timezone-formatted datetime, e.g.:
// 1568624680 -> Jan 1, 01:20
const timestampToFormattedDate = (timestamp) => {
  if (!timestamp) {
    return "N/A"
  }
  if (!/\d{10}/.test(String(timestamp))) {
    return `Invalid TS: ${timestamp}`
  }
  const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: false }
  const dateOptions = { month: "short", day: "2-digit" }

  const date = new Date(timestamp * 1000)
  const timeString = `${date.toLocaleTimeString([], timeOptions)}`
  const dateString = `${date.toLocaleDateString("en-US", dateOptions)}`
  return `${dateString}, ${timeString}`
}

const firebaseTsToSeconds = (firebaseTimestamp) => {
  if (!firebaseTimestamp) {
    return null
  }
  if (!(typeof firebaseTimestamp.toMillis === "function")) {
    return "Invalid Timestamp Format"
  }
  return firebaseTimestamp.toMillis() / 1000
}

const timeInSecondsToFormattedTime = (timeInSeconds, showHours = false) => {
  if (!timeInSeconds) {
    return "N/A"
  }
  let pad = (num, size) => {
    return ("000" + num).slice(size * -1)
  }
  const time = parseFloat(timeInSeconds).toFixed(3)
  // const hours = Math.floor(time / 60 / 60)
  const hours = Math.trunc(Math.floor(time / 60) / 60)
  const minutes = Math.floor(time / 60) % 60
  const seconds = Math.floor(time - minutes * 60)
  // milliseconds = time.slice(-3);
  if (hours >= 1 && showHours) {
    return `${hours}:${pad(minutes, 2)}:${pad(seconds, 2)}`
  } else {
    return `${pad(minutes, 2)}:${pad(seconds, 2)}`
  }
}

const isOlderThan = (created, hours) => {
  const now = Date.now()
  const timeInMs = hours * 60 * 60 * 1000
  return now - created > timeInMs
}

const daysToMs = (days) => {
  return days * 24 * 60 * 60 * 1000
}

export const time = {
  ONE_WEEK,
  firebaseTsToSeconds,
  getTimeNowInSeconds,
  isOlderThan,
  timeInSecondsToFormattedTime,
  timestampToFormattedDate,
  daysToMs,
}
