import _ from "lodash"

export const SPECIAL_SERVERS = Object.freeze([
  "datascience",
  "designtech",
  "devdb",
  "railsdevdb",
  "marketing",
  "radom",
  "sandbox",
  "social",
  "tamale",
  "wordpress",
  "zensell",
  "hubspotcrm",
  "panda",
  "duck",
])

const NONDEV_SERVERS = Object.freeze([
  "production",
  "staging",
  "stagingqa",
  "beta",
])

export class ViewModelServer {
  constructor(servers, currentUserId) {
    if (!currentUserId) {
      console.warn("current user id to operate fully", currentUserId)
    }

    if (!Array.isArray(servers)) {
      console.error("wrong servers data", servers)
      throw new Error("servers should be an array")
    }

    this._servers = servers
    this._currentUserId = currentUserId
  }
  // DEV SERVER Section
  devServers = () =>
    _.filter(
      this._servers,
      (s) => s.name !== "staging" && s.name !== "production"
    )
  freeDevServers = () =>
    _.filter(
      this.devServers(),
      (s) => !s.ownerUID && !s.isFavorite && !SPECIAL_SERVERS.includes(s.name)
    )

  anyFreeDevServerAvailable = () => this.freeDevServers().length > 0
  noFreeDevServerAvailable = () => !this.anyFreeDevServerAvailable()

  // Favorite / Mine Servers
  favorites = () => _.filter(this.devServers(), (s) => s.isFavorite)
  anyFavorites = () => this.favorites().length > 0
  noFavorites = () => !this.favorites()

  // User Servers
  userServers = () =>
    _.filter(
      this.devServers(),
      (s) => !s.isFavorite && s.ownerUID === this._currentUserId
    )
  anyUserServers = () => this.userServers().length > 0

  // Busy Servers
  busyServers = () =>
    _.filter(
      this.devServers(),
      (s) => s.ownerUID && !s.isFavorite && s.ownerUID !== this._currentUserId
    )
  anyBusyServers = () => this.busyServers().length > 0

  // Special Servers
  specialServers() {
    const servers = this._servers
    const specialSnowflakes = {
      special: _.filter(servers, (s) => SPECIAL_SERVERS.includes(s.name)),
      staging: _.filter(servers, (s) => s.name === "staging"),
      production: _.filter(servers, (s) => s.name === "production"),
    }
    return specialSnowflakes
  }

  /**
   * used to show custom e2e tags
   */
  static isDev(server) {
    return !NONDEV_SERVERS.includes(server.name)
  }
}
