import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Tooltip from "@material-ui/core/Tooltip"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import ErrorIcon from "@material-ui/icons/Error"
import ProductionDeploymentDialog from "./ProductionDeploymentDialog"
import CacheClearDialog from "./CacheClearDialog"

import Service from "../components/Service"
import ActionRow from "../components/ActionRow"
import ActionRowCreatorDBReset from "../components/ActionRowCreatorDBReset"
import ActionAutomationRowCreatorDBReset from "../components/ActionRowAutomationDbReset"
import { TimeElapsed } from "./Time"
import {
  deployCreatorInstance,
  deployLandingInstance,
  deployPlatformInstance,
} from "./../data/deployActions"
import { JOB_STATUS, PENDING_JOB_STATUSES } from "../const/jobs"
import { PERMISSION } from "../const/permission"
import { time } from "../utils/time"
import { Flags } from "../utils/featureFlags"
import { updateServerData, session } from "../data/data"
import { creatorResetDatabase, landingMigrateDB } from "../data/serviceActions"

const Separator = () => {
  return <span style={{ fontWeight: "400" }}> &gt; </span>
}

function isJobStatusPending(jobStatus) {
  // We are updating E2EJobStatus via test runner, so we need to check only partial value of status.
  // This fixes showing elapsed time on e2e job and locks the Run E2E button when test is running.
  // i.e. instead of E2EJobStatus="Running" we are passing for example E2EJobStatus="Running (12 left)"
  // TODO: refactor; move to a separate field, like E2ERunningTestsLeft: <number>
  return (
    jobStatus &&
    PENDING_JOB_STATUSES.some((status) => jobStatus.includes(status))
  )
}

const E2EStateRow = (props) => {
  const {
    jobStatus,
    jobAllowed,
    jobStartedTimestamp,
    lastJobStatus,
    lastJobCommitSha,
    lastJobURL,
    lastJobTimestamp,
    lastJobStartedTimestamp,
    lastJobFinishedTimestamp,
    onTriggerJobButtonClick,
    isProduction,
  } = props

  const jobDuration = time.timeInSecondsToFormattedTime(
    lastJobFinishedTimestamp - lastJobStartedTimestamp,
    true
  )

  const isPending = PENDING_JOB_STATUSES.includes(jobStatus)
  const issueDetected = [JOB_STATUS.ERROR, JOB_STATUS.FAILED].includes(
    lastJobStatus
  )

  const shortSha = () => {
    return lastJobCommitSha ? `${lastJobCommitSha}`.substring(0, 8) : ""
  }

  const commitURL = () => {
    if (!lastJobCommitSha) {
      return ""
    }
    const repoURL = "https://github.com/packhelp/packhelp"
    return `${repoURL}/commit/${lastJobCommitSha}`
  }

  const runLabel = `Run E2E`

  return isProduction ? null : (
    <Box className="app-state-row">
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <span alt="State Icon" style={{ fontSize: "16px" }}>
            👁
          </span>
          {lastJobCommitSha ? (
            <span style={{ marginLeft: "5px" }}>
              <a href={lastJobURL} target="_blank" rel="noopener noreferrer">
                <span>
                  {time.timestampToFormattedDate(lastJobFinishedTimestamp)}
                </span>
              </a>
              <span style={{ marginLeft: "10px" }}>
                {issueDetected ? (
                  <ErrorIcon
                    style={{
                      height: 17,
                      position: "relative",
                      top: 4,
                      color: "#e6543c",
                    }}
                  />
                ) : (
                  <CheckCircleIcon
                    style={{
                      height: 17,
                      position: "relative",
                      top: 4,
                      color: "#28cc5c",
                    }}
                  />
                )}
                <a href={commitURL()} target="_blank" rel="noopener noreferrer">
                  {shortSha()}
                </a>
              </span>
              <span style={{ marginLeft: "10px" }}>
                <span style={{ fontSize: "15px" }}>⏱</span> {jobDuration}
              </span>
            </span>
          ) : (
            <span style={{ marginLeft: "5px", color: "#9f9f9f" }}>
              No E2E test runs yet
            </span>
          )}
        </Box>
        <Box style={{ display: "flex" }}>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            style={{
              minWidth: "160px",
              maxHeight: "25px",
              lineHeight: "16px",
              fontSize: "11px",
            }}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onTriggerJobButtonClick}
            disabled={!jobAllowed}
          >
            {!isPending ? (
              runLabel
            ) : (
              <TimeElapsed
                isClockTicking={isPending}
                startTimestamp={jobStartedTimestamp}
                endTimestamp={lastJobTimestamp}
              />
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

const JobRow = (props) => {
  const {
    jobName,
    jobUrl,
    jobStatus,
    jobNumber,
    jobStartedTimestamp,
    jobFinishedTimestamp,
    onReset,
  } = props

  const [mouseOver, setMouseOver] = useState(false)

  const jobTime = time.timeInSecondsToFormattedTime(
    jobFinishedTimestamp - jobStartedTimestamp
  )

  const isPending = isJobStatusPending(jobStatus)
  const displayJobRow = jobStatus && !(jobStatus === JOB_STATUS.IDLE)
  const handleReset = () => {
    const confirmMsg =
      "Resetting the job only clears it in the UI. The " +
      "job will still run on CI until failed or completed. " +
      "Are you sure you want to proceed?"
    if (window.confirm(confirmMsg)) {
      onReset()
    }
  }

  const onMouseOver = () => {
    setMouseOver(true)
  }

  const onMouseAway = () => {
    setMouseOver(false)
  }

  const renderDebugLinks = () => {
    if (jobName === "E2E Test Run") return renderE2EDebugLinks()
    return (
      <a href={jobUrl} rel="noopener noreferrer" target="_blank">
        {jobNumber}
      </a>
    )
  }

  const renderE2EDebugLinks = () => {
    const debugUrl = `https://eye.packhelp.com/app/1/${jobNumber}`

    return (
      <>
        Debug{" "}
        <a href={jobUrl} rel="noopener noreferrer" target="_blank">
          <span role="img" aria-label="CI">
            ⚙️
          </span>{" "}
          CI
        </a>{" "}
        <a href={debugUrl} rel="noopener noreferrer" target="_blank">
          👁 Tests
        </a>
      </>
    )
  }

  if (!displayJobRow) {
    return null
  }

  return (
    <Typography
      variant="caption"
      style={{ width: "100%", fontWeight: "500", display: "block" }}
      onMouseEnter={onMouseOver}
      onMouseLeave={onMouseAway}
    >
      <span>
        {"• "}
        {jobFinishedTimestamp ? (
          <Tooltip title={`Finished in: ${jobTime}`} placement="top">
            <span>{time.timestampToFormattedDate(jobStartedTimestamp)}</span>
          </Tooltip>
        ) : (
          <span style={{ color: "darkorange" }}>
            <TimeElapsed
              isClockTicking={isPending}
              startTimestamp={jobStartedTimestamp}
              endTimestamp={jobFinishedTimestamp}
            />
          </span>
        )}
      </span>
      <Separator />
      <span>{jobName}</span>
      <Separator />
      {jobNumber && (
        <span>
          <span>{jobUrl ? renderDebugLinks() : <span>{jobNumber}</span>}</span>
          <Separator />
        </span>
      )}
      <JobStatusText
        jobStatus={jobStatus}
        jobFinishedTimestamp={jobFinishedTimestamp}
      />
      {mouseOver ? (
        <Button
          color="secondary"
          size="small"
          onClick={handleReset}
          style={{
            padding: "0px 8px",
            lineHeight: "16px",
            position: "relative",
            bottom: "1px",
            marginLeft: "5px",
          }}
        >
          Reset
        </Button>
      ) : null}
    </Typography>
  )
}

const JobStatusText = (props) => {
  const { jobStatus, jobFinishedTimestamp } = props

  const statusColor = () => {
    if (jobStatus === JOB_STATUS.PASSED) return "green"
    if (!jobFinishedTimestamp) return "black"
    return "red"
  }

  return <span style={{ color: statusColor() }}>{jobStatus}</span>
}

const ActionPurgeCache = (props) => {
  return (
    <ActionRow
      show={true}
      name="Purge Cache (LP)"
      buttonLabel="Purge"
      disabled={false}
      onButtonClick={props.onClick.bind(this)}
      tooltipTitle="Trigger Varnish or Landing Cache Purge"
    />
  )
}

export const DeploymentCard = (props) => {
  const {
    name,
    herokuAppName,
    herokuAppNamePlatform,
    branch,
    appUrl,
    creatorAutoDeploy,
    creatorDeploymentUrl,
    creatorDeploymentStatus,
    creatorDeploymentNumber,
    creatorDeploymentStartedTimestamp,
    creatorDeploymentFinishedTimestamp,
    creatorLastDeploymentCommit,
    creatorLastDeploymentNumber,
    creatorLastDeploymentStartedTimestamp,
    creatorLastDeploymentFinishedTimestamp,
    creatorDatabaseResetJobStartedTimestamp,
    creatorDatabaseResetJobFinishedTimestamp,
    creatorLastDatabaseResetJobStartedTimestamp,
    creatorLastDatabaseResetJobFinishedTimestamp,
    creatorLastDatabaseResetJobStatus,
    creatorDatabaseResetJobStatus,
    creatorDatabaseResetJobUrl,
    creatorDatabaseResetJobNumber,
    herokuCreatorDynoScale,
    platformAutoDeploy,
    platformDeploymentFinishedTimestamp,
    platformDeploymentNumber,
    platformDeploymentStartedTimestamp,
    platformDeploymentStatus,
    platformDeploymentUrl,
    platformLastDeploymentCommit,
    platformLastDeploymentFinishedTimestamp,
    platformLastDeploymentNumber,
    platformLastDeploymentStartedTimestamp,
    landingAutoDeploy,
    landingDeploymentUrl,
    landingDeploymentNumber,
    landingDeploymentStatus,
    landingDeploymentStartedTimestamp,
    landingDeploymentFinishedTimestamp,
    landingLastDeploymentCommit,
    landingLastDeploymentNumber,
    landingLastDeploymentStartedTimestamp,
    landingLastDeploymentFinishedTimestamp,
    landingDatabaseMigrateJobStatus,
    landingDatabaseMigrateJobUrl,
    landingDatabaseMigrateJobNumber,
    landingDatabaseMigrateJobStartedTimestamp,
    landingDatabaseMigrateJobFinishedTimestamp,
    landingLastDatabaseMigrateJobStartedTimestamp,
    landingLastDatabaseMigrateJobFinishedTimestamp,
    E2EJobUrl,
    E2EJobStatus,
    E2EJobNumber,
    E2EJobStartedTimestamp,
    E2EJobFinishedTimestamp,
    E2ELastJobStatus,
    E2ELastJobCommit,
    E2ELastJobUrl,
    E2ELastJobNumber,
    E2ELastJobStartedTimestamp,
    E2ELastJobFinishedTimestamp,
    production,
    openRunE2EModal,
    creatorDailyDBReset,
  } = props

  const isProduction = Boolean(production)
  const isStaging = name === "staging"
  const isDev = !isProduction && !isStaging
  const [featureFlags, setFeatureFlags] = useState(Flags.raw)
  const [deployConfirmService, setDeployConfirmService] = useState(null)
  const [deployConfirmOpen, setDeployConfirmOpen] = useState(false)
  const [cacheClearOpen, setCacheClearConfirmOpen] = useState(false)

  const [deployConfirmFn, setDeployConfirmFn] = useState(null)

  useEffect(() => {
    const unsubscribe = Flags.subscribe((newFlags) => {
      setFeatureFlags(newFlags)
    })
    return unsubscribe
  }, [])

  function confirmDeployment({ service, deployFn }) {
    setDeployConfirmFn(() => deployFn)
    setDeployConfirmService(service)
    setDeployConfirmOpen(true)
  }

  function openCacheClearDialog() {
    setCacheClearConfirmOpen(true)
  }

  const deploymentAllowed = ({
    branch,
    isProduction,
    serviceDeploymentJobStatus,
    e2eTestsJobStatus,
    requiredProductionPermissions,
    serviceName,
  }) => {
    const areE2eTestsRunning =
      e2eTestsJobStatus && e2eTestsJobStatus.includes(JOB_STATUS.RUNNING)
    if (areE2eTestsRunning) {
      return false
    }
    if (isProduction) {
      if (
        !session.hasPermissions(requiredProductionPermissions) ||
        window.__env__.REACT_APP_PRODUCTION_DEPLOYMENT_DISABLED === "true"
      ) {
        return false
      }
    }
    if (
      serviceName === "creator" &&
      !featureFlags.REACT_APP_CREATOR_DEPLOYMENT_ENABLED
    ) {
      return false
    }
    if (
      serviceName === "landing" &&
      !featureFlags.REACT_APP_LANDING_DEPLOYMENT_ENABLED
    ) {
      return false
    }
    if (
      serviceName === "platform" &&
      !featureFlags.REACT_APP_PLATFORM_DEPLOYMENT_ENABLED
    ) {
      return false
    }
    const isBranchEmpty = !branch
    const isDeploymentTriggered =
      serviceDeploymentJobStatus === JOB_STATUS.TRIGGERED
    const isJobPending = PENDING_JOB_STATUSES.includes(
      serviceDeploymentJobStatus
    )
    return !isBranchEmpty && !isDeploymentTriggered && !isJobPending
  }

  const E2EJobAllowed = ({
    branch,
    isProduction,
    e2eJobStatus,
    isAnyDeploymentInProgress,
  }) => {
    if (isProduction) {
      return false
    }
    const isBranchEmpty = !branch
    const isJobPending = isJobStatusPending(e2eJobStatus)
    return !isBranchEmpty && !isJobPending && !isAnyDeploymentInProgress
  }

  const jobNotIdle = (status) => status && status !== JOB_STATUS.IDLE

  const displayLastJobs =
    jobNotIdle(creatorDeploymentStatus) ||
    jobNotIdle(landingDeploymentStatus) ||
    jobNotIdle(platformDeploymentStatus) ||
    jobNotIdle(E2EJobStatus) ||
    jobNotIdle(creatorDatabaseResetJobStatus) ||
    jobNotIdle(landingDatabaseMigrateJobStatus)

  const isAnyDeploymentInProgress =
    jobNotIdle(creatorDeploymentStatus) ||
    jobNotIdle(landingDeploymentStatus) ||
    jobNotIdle(platformDeploymentStatus)

  const triggerLandingDeployment = () => {
    let deploy = ({ isHotfix = false }) => {
      return deployLandingInstance({
        appUrl,
        branch,
        herokuAppName,
        isRuntimeKubernetes: true,
        isHotfix: isHotfix,
        serverName: name,
        triggeredByUID: session.get().uid,
        triggeredManually: true,
      })
    }
    if (name !== "production") {
      return deploy({ isHotfix: false })
    }
    if (!session.hasPermission(PERMISSION.DEPLOY_LANDING)) {
      console.log("Aborting deployment..")
      return alert("Sorry! You are not authorized to perform this deployment.")
    }

    confirmDeployment({
      service: "landing",
      deployFn: deploy,
    })
  }

  const triggerCreatorDeployment = () => {
    let deploy = ({ isHotfix = false, migrationsScenario = null }) => {
      return deployCreatorInstance({
        appUrl,
        branch,
        herokuAppName,
        isRuntimeKubernetes: false,
        isHotfix,
        migrationsScenario,
        serverName: name,
        triggeredByUID: session.get().uid,
        triggeredManually: true,
      })
    }
    if (name !== "production") {
      return deploy({ isHotfix: false })
    }
    if (!session.hasPermission(PERMISSION.DEPLOY_CREATOR)) {
      console.log("Aborting deployment..")
      return alert("Sorry! You are not authorized to perform this deployment.")
    }

    confirmDeployment({
      service: "creator",
      deployFn: deploy,
    })
  }

  const triggerPlatformDeployment = () => {
    let deploy = ({ isHotfix = false }) => {
      return deployPlatformInstance({
        appUrl,
        branch,
        herokuAppNamePlatform,
        isRuntimeKubernetes: true,
        isHotfix: isHotfix,
        serverName: name,
        triggeredByUID: session.get().uid,
        triggeredManually: true,
      })
    }
    if (name !== "production") {
      return deploy({ isHotfix: false })
    }
    if (!session.hasPermission(PERMISSION.DEPLOY_PLATFORM)) {
      console.log("Aborting deployment..")
      return alert("Sorry! You are not authorized to perform this deployment.")
    }

    confirmDeployment({
      service: "platform",
      deployFn: deploy,
    })
  }

  const setCreatorDailyDBReset = (dailyResetEnabled) => {
    updateServerData(name, { creatorDailyDBReset: dailyResetEnabled })
  }

  const resetLandingDeployJobStatus = () => {
    updateServerData(name, { landingDeploymentStatus: JOB_STATUS.IDLE })
  }

  const resetPlatformDeployJobStatus = () => {
    updateServerData(name, { platformDeploymentStatus: JOB_STATUS.IDLE })
  }

  const resetCreatorDeployJobStatus = () => {
    updateServerData(name, { creatorDeploymentStatus: JOB_STATUS.IDLE })
  }

  const resetE2EJobStatus = () => {
    updateServerData(name, { E2EJobStatus: JOB_STATUS.IDLE })
  }

  const resetCreatorDatabaseJobStatus = () => {
    updateServerData(name, { creatorDatabaseResetJobStatus: JOB_STATUS.IDLE })
  }

  const resetLandingDatabaseJobStatus = () => {
    updateServerData(name, { landingDatabaseMigrateJobStatus: JOB_STATUS.IDLE })
  }

  const triggerCreatorDBReset = ({ source, snapshotProvider }) => {
    if (!["dev", "production"].includes(source)) {
      throw new Error("Invalid DB reset source. Should be [dev, production].")
    }
    creatorResetDatabase({
      source,
      snapshotProvider,
      env: name,
      gitRef: branch,
      triggeredByUID: session.get().uid,
    })
  }

  const triggerLandingDatabaseMigration = () => {
    landingMigrateDB({
      env: name,
      gitRef: branch,
      triggeredByUID: session.get().uid,
    })
  }

  function deployCreatorButtonLabel() {
    return `Deploy Creator`
  }

  function creatorResetDatabaseLabel() {
    return `Reset DB..`
  }

  function deployPlatformButtonLabel() {
    return "Deploy Platform"
  }

  function deployLandingButtonLabel() {
    return "Deploy Landing"
  }

  return (
    <Box
      style={{
        minWidth: 450,
        maxWidth: "100%",
        textAlign: "left",
        padding: "10px 10px 10px 10px",
      }}
    >
      <Typography> Services </Typography>
      <ProductionDeploymentDialog
        open={deployConfirmOpen}
        deployFn={deployConfirmFn}
        service={deployConfirmService}
        setOpen={setDeployConfirmOpen}
      />
      <CacheClearDialog
        open={cacheClearOpen}
        setOpen={setCacheClearConfirmOpen}
      ></CacheClearDialog>
      <Service
        name="LANDING"
        serverName={name}
        iconSrc="wordpress-icon.svg"
        autoDeploy={landingAutoDeploy}
        autoDeployName="landingAutoDeploy"
        deploymentStatus={landingDeploymentStatus}
        deploymentAllowed={deploymentAllowed({
          branch,
          e2eTestsJobStatus: E2EJobStatus,
          isProduction: Boolean(production),
          requiredProductionPermissions: [PERMISSION.DEPLOY_LANDING],
          serviceDeploymentJobStatus: landingDeploymentStatus,
          serviceName: "landing",
        })}
        deployButtonLabel={deployLandingButtonLabel()}
        deploymentStartedTimestamp={landingDeploymentStartedTimestamp}
        lastDeploymentCommitSha={landingLastDeploymentCommit}
        lastDeploymentNumber={landingLastDeploymentNumber}
        lastDeploymentURL={landingDeploymentUrl}
        lastDeploymentStartedTimestamp={landingLastDeploymentStartedTimestamp}
        lastDeploymentFinishedTimestamp={landingLastDeploymentFinishedTimestamp}
        triggerDeployment={triggerLandingDeployment}
        isProduction={Boolean(production)}
      />
      <Service
        name="CREATOR"
        serverName={name}
        iconSrc="ruby-icon.png"
        autoDeploy={creatorAutoDeploy}
        autoDeployName="creatorAutoDeploy"
        deploymentStatus={creatorDeploymentStatus}
        deploymentAllowed={deploymentAllowed({
          branch,
          e2eTestsJobStatus: E2EJobStatus,
          isProduction: Boolean(production),
          requiredProductionPermissions: [PERMISSION.DEPLOY_CREATOR],
          serviceDeploymentJobStatus: creatorDeploymentStatus,
          serviceName: "creator",
        })}
        deployButtonLabel={deployCreatorButtonLabel()}
        deploymentStartedTimestamp={creatorDeploymentStartedTimestamp}
        lastDeploymentCommitSha={creatorLastDeploymentCommit}
        lastDeploymentNumber={creatorLastDeploymentNumber}
        lastDeploymentURL={creatorDeploymentUrl}
        lastDeploymentStartedTimestamp={creatorLastDeploymentStartedTimestamp}
        lastDeploymentFinishedTimestamp={creatorLastDeploymentFinishedTimestamp}
        triggerDeployment={triggerCreatorDeployment}
        isProduction={Boolean(production)}
      />
      <Service
        name="PLATFORM"
        serverName={name}
        iconSrc="packhelp-logo-small.png"
        autoDeploy={platformAutoDeploy}
        autoDeployName="platformAutoDeploy"
        deploymentStatus={platformDeploymentStatus}
        deploymentAllowed={deploymentAllowed({
          branch,
          e2eTestsJobStatus: E2EJobStatus,
          isProduction: Boolean(production),
          requiredProductionPermissions: [PERMISSION.DEPLOY_PLATFORM],
          serviceDeploymentJobStatus: platformDeploymentStatus,
          serviceName: "platform",
        })}
        deployButtonLabel={deployPlatformButtonLabel()}
        deploymentStartedTimestamp={platformDeploymentStartedTimestamp}
        lastDeploymentCommitSha={platformLastDeploymentCommit}
        lastDeploymentNumber={platformLastDeploymentNumber}
        lastDeploymentURL={platformDeploymentUrl}
        lastDeploymentStartedTimestamp={platformLastDeploymentStartedTimestamp}
        lastDeploymentFinishedTimestamp={
          platformLastDeploymentFinishedTimestamp
        }
        triggerDeployment={triggerPlatformDeployment}
        isProduction={Boolean(production)}
      ></Service>
      {/* Dev Actions */}
      {!isProduction && (
        <Box>
          <Typography style={{ marginTop: "10px" }}> Actions </Typography>
          <ActionRowCreatorDBReset
            show={!Boolean(production)}
            name="Creator Database"
            hideResetToDev={false}
            jobStatus={creatorDatabaseResetJobStatus}
            lastStatus={creatorLastDatabaseResetJobStatus}
            herokuCreatorDynoScale={herokuCreatorDynoScale}
            buttonLabel={creatorResetDatabaseLabel()}
            lastActionStartedTimestamp={
              creatorLastDatabaseResetJobStartedTimestamp
            }
            lastActionFinishedTimestamp={
              creatorLastDatabaseResetJobFinishedTimestamp
            }
            actionStartedTimestamp={creatorDatabaseResetJobStartedTimestamp}
            disabled={PENDING_JOB_STATUSES.includes(
              creatorDatabaseResetJobStatus
            )}
            lastDate={creatorLastDatabaseResetJobFinishedTimestamp}
            actionHandler={triggerCreatorDBReset}
            tooltipTitle="This will trigger creator database reset.). DB reset takes ~10-15 minutes, PROD around 1 hour! All your data will be lost!"
          />
          <ActionRow
            show={isDev}
            name="Landing Database"
            jobStatus={landingDatabaseMigrateJobStatus}
            lastStatus={JOB_STATUS.PASSED}
            buttonLabel="Migrate"
            lastActionStartedTimestamp={
              landingLastDatabaseMigrateJobStartedTimestamp
            }
            lastActionFinishedTimestamp={
              landingLastDatabaseMigrateJobFinishedTimestamp
            }
            actionStartedTimestamp={landingDatabaseMigrateJobStartedTimestamp}
            disabled={PENDING_JOB_STATUSES.includes(
              landingDatabaseMigrateJobStatus
            )}
            lastDate={landingLastDatabaseMigrateJobFinishedTimestamp}
            onButtonClick={triggerLandingDatabaseMigration.bind(this)}
            tooltipTitle="This will trigger landing database migration from STAGING server. All your data will be lost!"
          />
        </Box>
      )}
      {!isProduction && (
        <Box>
          <Typography style={{ marginTop: "10px" }}>
            {" "}
            Automation Actions{" "}
          </Typography>
          <ActionAutomationRowCreatorDBReset
            show={!Boolean(production)}
            name={
              isDev
                ? "Creator Database Reset To Dev"
                : "Creator Database Reset To Prod"
            }
            onCheckboxClick={setCreatorDailyDBReset}
            creatorDailyDBReset={creatorDailyDBReset}
            tooltipTitle="This will enable daily creator database resets at 2:00 a.m every night from monday to friday. All your data will be lost! If you need to keep the same database data for couple days, please uncheck this checkbox."
          />
        </Box>
      )}
      {/* Production Actions */}
      {isProduction && (
        <Box>
          <Typography style={{ marginTop: "10px" }}> Actions </Typography>
          <ActionPurgeCache onClick={openCacheClearDialog}></ActionPurgeCache>
        </Box>
      )}

      {!Boolean(production) && (
        <Box>
          <Typography style={{ marginTop: "10px" }}> E2E </Typography>
          <E2EStateRow
            jobStatus={E2EJobStatus}
            jobAllowed={E2EJobAllowed({
              branch,
              isProduction: Boolean(production),
              requiredProductionPermissions: [PERMISSION.DEPLOY_CREATOR],
              e2eJobStatus: E2EJobStatus,
              isAnyDeploymentInProgress,
            })}
            jobStartedTimestamp={E2EJobStartedTimestamp}
            lastJobStatus={E2ELastJobStatus}
            lastJobCommitSha={E2ELastJobCommit}
            lastJobNumber={E2ELastJobNumber}
            lastJobURL={E2ELastJobUrl}
            lastJobStartedTimestamp={E2ELastJobStartedTimestamp}
            lastJobFinishedTimestamp={E2ELastJobFinishedTimestamp}
            onTriggerJobButtonClick={openRunE2EModal}
            isProduction={Boolean(production)}
          />
        </Box>
      )}
      <div style={{ paddingLeft: "6px" }}>
        <Typography
          variant="caption"
          display="block"
          style={{ marginTop: 5, fontSize: 11 }}
        >
          Running Jobs (<Link to={`jobs?server=${name}`}>Show All</Link>)
        </Typography>
        {displayLastJobs && (
          <Typography variant="caption" display="block">
            <JobRow
              jobName="Creator Deploy"
              jobUrl={creatorDeploymentUrl}
              jobStatus={creatorDeploymentStatus}
              jobNumber={creatorDeploymentNumber}
              jobStartedTimestamp={creatorDeploymentStartedTimestamp}
              jobFinishedTimestamp={creatorDeploymentFinishedTimestamp}
              onReset={resetCreatorDeployJobStatus}
            />
            <JobRow
              jobName="Landing Deploy"
              jobUrl={landingDeploymentUrl}
              jobStatus={landingDeploymentStatus}
              jobNumber={landingDeploymentNumber}
              jobStartedTimestamp={landingDeploymentStartedTimestamp}
              jobFinishedTimestamp={landingDeploymentFinishedTimestamp}
              onReset={resetLandingDeployJobStatus}
            />
            <JobRow
              jobName="Platform Deploy"
              jobUrl={platformDeploymentUrl}
              jobStatus={platformDeploymentStatus}
              jobNumber={platformDeploymentNumber}
              jobStartedTimestamp={platformDeploymentStartedTimestamp}
              jobFinishedTimestamp={platformDeploymentFinishedTimestamp}
              onReset={resetPlatformDeployJobStatus}
            />
            <JobRow
              jobName="E2E Test Run"
              jobUrl={E2EJobUrl}
              jobStatus={E2EJobStatus}
              jobNumber={E2EJobNumber}
              jobStartedTimestamp={E2EJobStartedTimestamp}
              jobFinishedTimestamp={E2EJobFinishedTimestamp}
              onReset={resetE2EJobStatus}
            />
            <JobRow
              jobName="Creator Database Reset"
              jobUrl={creatorDatabaseResetJobUrl}
              jobStatus={creatorDatabaseResetJobStatus}
              jobNumber={creatorDatabaseResetJobNumber}
              jobStartedTimestamp={creatorDatabaseResetJobStartedTimestamp}
              jobFinishedTimestamp={creatorDatabaseResetJobFinishedTimestamp}
              onReset={resetCreatorDatabaseJobStatus}
            />
            <JobRow
              jobName="Landing Database Migration"
              jobUrl={landingDatabaseMigrateJobUrl}
              jobStatus={landingDatabaseMigrateJobStatus}
              jobNumber={landingDatabaseMigrateJobNumber}
              jobStartedTimestamp={landingDatabaseMigrateJobStartedTimestamp}
              jobFinishedTimestamp={landingDatabaseMigrateJobFinishedTimestamp}
              onReset={resetLandingDatabaseJobStatus}
            />
          </Typography>
        )}
      </div>
    </Box>
  )
}

export default DeploymentCard
