import React, { useState, useEffect } from "react"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core"

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormGroup from "@material-ui/core/FormGroup"
import Switch from "@material-ui/core/Switch"
import Typography from "@material-ui/core/Typography"

import { Flags } from "../utils/featureFlags"

const useStyles = makeStyles({
  container: {
    zIndex: "999",
    position: "fixed",
    padding: "10px 20px",
    right: 20,
    bottom: 20,
  },
})

const FeatureFlagsPanel = () => {
  const classes = useStyles()
  const [hidden, setHidden] = useState(true)
  const [featureFlags, setFeatureFlags] = useState(Flags.raw)

  useEffect(() => {
    const unsubscribe = Flags.subscribe((newFlags) => {
      setFeatureFlags(newFlags)
    })
    return unsubscribe
  }, [])

  const toggleVisiblityButton = (
    <Button
      color="primary"
      className={classes.visiblityToggle}
      onClick={() => setHidden(!hidden)}
    >
      Feature Flags
    </Button>
  )

  return hidden ? (
    <Box>
      <Paper className={classes.container}>
        <Box style={{ textAlign: "right" }}>{toggleVisiblityButton}</Box>
      </Paper>
    </Box>
  ) : (
    <Box>
      <Paper className={classes.container}>
        <FormGroup>
          {Object.keys(featureFlags).map((key, i) => (
            <FormControlLabel
              key={i}
              control={
                <Switch
                  color="primary"
                  checked={featureFlags[key]}
                  onChange={() => {
                    Flags.update(key, !featureFlags[key])
                  }}
                />
              }
              label={`${key.replace("REACT_APP_", "")}`}
            />
          ))}
        </FormGroup>
        <Typography
          variant="body2"
          style={{ padding: "15px 0", color: "#777", maxWidth: 350 }}
        >
          Hi there! Please switch these flags only if you know what you're doing
          or you might break a server.{" "}
          <span role="img" aria-label="Pray emoji">
            🙏
          </span>
        </Typography>
        <Box style={{ textAlign: "right" }}>{toggleVisiblityButton}</Box>
      </Paper>
    </Box>
  )
}

export default FeatureFlagsPanel
