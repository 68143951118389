import React from "react"
import { useState, useEffect } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Link from "@material-ui/core/Link"
import Typography from "@material-ui/core/Typography"

import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"

export default function ProductionDeploymentDialog({
  deployFn,
  open,
  service,
  setOpen,
}) {
  const [isHotfix, setIsHotfix] = useState(null)
  const [migrationsScenario, setMigrationsScenario] = useState(null)
  const [validatedAtLeastOnce, setValidatedAtLeastOnce] = useState(false)

  function validate() {
    setValidatedAtLeastOnce(true)
    if (service !== "creator") {
      return isHotfix !== null
    }
    return isHotfix !== null && migrationsScenario !== null
  }

  // Clear values on closing the modal
  useEffect(() => {
    if (!open) {
      setIsHotfix(null)
      setMigrationsScenario(null)
      setValidatedAtLeastOnce(false)
    }
  }, [open])

  const handleDeployment = () => {
    if (!validate()) {
      return
    }
    if (service === "creator") {
      deployFn({
        isHotfix: isHotfix === "yes",
        migrationsScenario,
      })
    } else {
      deployFn({
        isHotfix: isHotfix === "yes",
      })
    }
    setOpen(false)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {`⚠️ Confirm production deployment: `}
        <span style={{ color: "#3f51b5" }}>{service}</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Card>
            <CardContent>
              <div>
                <Typography color="textSecondary" gutterBottom>
                  Regular release or hotfix?
                </Typography>
                <Typography gutterBottom variant="body2">
                  A <b>regular release</b> is every normal release, however
                  small.
                  <br />A <b>hotfix</b> is any urgent fix, problems guaranteed
                  if not deployed quickly.
                  <br />
                </Typography>

                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Wondering why we track this?{" "}
                  <Link
                    href="https://www.notion.so/packhelp/DevOps-Four-Key-Metrics-a20ab82d49f54c8e92f433fb95b69a65"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read about DORA metrics.
                  </Link>
                </Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="hotfix"
                    name="hotfix"
                    value={isHotfix}
                    onChange={(ev) => setIsHotfix(ev.target.value)}
                  >
                    <FormControlLabel
                      value="no"
                      control={<Radio color="primary" />}
                      label="Regular release"
                    />
                    <FormControlLabel
                      value="yes"
                      control={<Radio color="primary" />}
                      label="Hotfix"
                    />
                  </RadioGroup>
                </FormControl>
                {validatedAtLeastOnce && isHotfix === null && (
                  <Typography paragraph color="secondary" variant="body2">
                    Please select one option.
                  </Typography>
                )}

                <Typography variant="body2" color="textSecondary" gutterBottom>
                  {" "}
                  <b>
                    IMPORTANT: If you're introducing a breaking change
                    (backwards incompatible) to any API that landing is
                    dependent on (Rails API, etc.), please note that some users
                    might have the old JS bundle running, requesting no longer
                    available endpoints, resulting in errors. This behaviour
                    will persist for TTL time (e.g. 1 hour). For example,
                    changing endpoint /account to /userAccount` will result in
                    such errors. If you must do such changes, clear Varnish
                    cache after changing the dependent API(s).
                  </b>{" "}
                </Typography>
              </div>
            </CardContent>
          </Card>

          <br />

          {service === "creator" && (
            <Card>
              <CardContent>
                <div>
                  <Typography color="textSecondary" gutterBottom>
                    Do the changes involve breaking DB migrations?
                  </Typography>
                  <Typography paragraph variant="body2">
                    A breaking migration is when post-deploy code is not
                    compatible with pre-deploy DB schema (or vice versa). For
                    example, <b>renaming</b> a DB column is a{" "}
                    <b>breaking migration</b>.{" "}
                    <Link
                      href="https://www.notion.so/packhelp/CREATOR-001-DB-Migrations-during-releases-ebdfe729b1684035b96e4d50f70812b3?pvs=4"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Read more about this change.
                    </Link>
                  </Typography>
                  <Typography paragraph variant="body2">
                    <b>NOTE</b>: If you select breaking migrations, the
                    deployment will incur a short downtime (for the duration of
                    migrations). To avoid triggering alerts,{" "}
                    <Link
                      href="https://app.datadoghq.com/monitors/downtimes?q=monitor_tags:%22service:creator%22&sort=-start_dt"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      schedule downtime in Datadog
                    </Link>
                    .
                    <br />
                    <br />
                    <Link
                      href="https://app.datadoghq.com/logs?query=container_name:creator-migrations%20env:production&cols=host,service&index=*&messageDisplay=inline&stream_sort=desc&viz=stream&from_ts=1687787202364&to_ts=1687801602364&live=true"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Browse DB migrations logs in Datadog.
                    </Link>
                  </Typography>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="migrations-scenario"
                      name="migrations-scenario"
                      value={migrationsScenario}
                      onChange={(ev) => setMigrationsScenario(ev.target.value)}
                    >
                      <FormControlLabel
                        value="NO_MIGRATIONS"
                        control={<Radio color="primary" />}
                        label="No migrations"
                      />
                      <FormControlLabel
                        value="NON_BREAKING_MIGRATIONS"
                        control={<Radio color="primary" />}
                        label="Non-breaking migrations"
                      />
                      <FormControlLabel
                        value="BREAKING_MIGRATIONS"
                        control={<Radio color="primary" />}
                        label="Breaking migrations (requires downtime)"
                      />
                    </RadioGroup>
                  </FormControl>
                  {validatedAtLeastOnce && migrationsScenario === null && (
                    <Typography paragraph color="secondary" variant="body2">
                      Please select one option.
                    </Typography>
                  )}
                </div>
              </CardContent>
            </Card>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDeployment} variant="contained" color="primary">
          Deploy
        </Button>
      </DialogActions>
    </Dialog>
  )
}
