import React from "react"

import {
  BrowserRouter as Router,
  NavLink,
  Route,
  Switch,
} from "react-router-dom"

// import Alert from "@material-ui/lab/Alert"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Logo from "./components/Logo"

import "./App.css"
import { session } from "./data/data"
import { JobsView } from "./views/jobs-view/JobsView"
import { LoggedUserBadge, LoggedUserPermissionsHint } from "./Login"
import FooterMessage from "./components/FooterMessage"
import FeatureFlagsPanel from "./views/FeatureFlagsPanel"
import ServersView from "./views/servers-view/ServersView"
import history from "./history"

export function App({ initialUser }) {
  const user = initialUser

  return (
    <Router history={history}>
      <Container maxWidth="lg" style={{ padding: "30px 20px 5px 10px" }}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Logo variant="h3" src="./logo.png" />

          <Box className="header-navigation">
            <NavLink exact={true} activeClassName="navlink-active" to="/">
              <Button color="primary">Servers</Button>
            </NavLink>
            <NavLink activeClassName="navlink-active" to="/jobs">
              <Button color="primary">Jobs</Button>
            </NavLink>
          </Box>

          <Box style={{ padding: "0 0 7px 0" }}>
            <LoggedUserPermissionsHint permissions={session.getPermissions()} />
            <LoggedUserBadge
              style={{ position: "relative", marginLeft: "10px" }}
              user={user}
            />
          </Box>
        </Grid>
      </Container>

      {/*
      <Container>
        <Container maxWidth="lg" style={{ padding: "0px 10px 20px 10px" }}>
          <Alert severity="info">
            SAMPLE MESSAGE TO APPEAR ON TOP
            Platform migrated to K8S (19.01.2022, 13:30 CET). If you see any
            weird behaviour, please contact @ops on{" "}
            <a href="https://packhelp.slack.com/archives/C01EH2D2F2T">
              #dev-team-ops-delivery-engineering
            </a>
          </Alert>
        </Container>
      </Container>
      */}

      <Switch>
        <Route exact path="/">
          <ServersView user={user} />
        </Route>
        <Route path="/jobs">
          <JobsView />
        </Route>
      </Switch>

      <FooterMessage />

      <FeatureFlagsPanel />
    </Router>
  )
}
