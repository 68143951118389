import React from "react"

import IconButton from "@material-ui/core/IconButton"
import FavoriteIcon from "@material-ui/icons/Favorite"
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder"

const FavoriteToggle = (props) => {
  let button = <FavoriteBorderIcon></FavoriteBorderIcon>

  if (props.pinned) {
    button = <FavoriteIcon></FavoriteIcon>
  }

  const handleClick = () => {
    props.onClick()
  }
  let styles = {}
  return (
    <IconButton onClick={handleClick.bind(this)} style={styles}>
      {button}
    </IconButton>
  )
}

export default FavoriteToggle
