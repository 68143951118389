import React, { useState } from "react"
import { triggerWorkflowJob } from "../data/workflowJobs"
import { session } from "../data/data"

import { Button } from "@material-ui/core"

const ActionPurgeVarnishCache = () => {
  const [purging, setPurgeButtonLock] = useState(false)
  const triggerVarnishCachePurge = async () => {
    const confirmMsg =
      `This will clear entire Varnish cache. Website may perform worsen until cache regenerates.` +
      `Please allow 30s-2m for the operation to take full effect. ` +
      `Are you sure you want to proceed?`
    if (window.confirm(confirmMsg)) {
      setPurgeButtonLock(true)
      triggerWorkflowJob({
        repo: "packhelp/packhelp",
        workflow: "restart-varnish.yaml",
        env: "production",
        gitRef: "master",
        jobType: "CLEAR_VARNISH_CACHE",
        triggeredByUID: session.get().email,

        options: {
          workflowInputs: {
            cluster: "pr",
            namespace: "app-landing-production",
          },
        },
      })
      setPurgeButtonLock(false)
      alert(
        `Started job for clearing Varnish cache. You can see progress here: https://github.com/packhelp/packhelp/actions/workflows/restart-varnish.yaml`
      )
    }
  }
  const label = purging ? "Clearing.." : "Clear Varnish Cache"

  return (
    <Button
      disabled={purging}
      onClick={triggerVarnishCachePurge.bind(this)}
      variant="contained"
      color="primary"
      size="small"
    >
      {label}
    </Button>
  )
}

export default ActionPurgeVarnishCache
