import React, { useState } from "react"
import { clearCloudflareCache } from "../data/serviceActions"
import { Button } from "@material-ui/core"

const ActionPurgeCloudflareCache = () => {
  const [purging, setPurging] = useState(false)
  const triggerCloudflareCachePurge = async () => {
    const confirmMsg =
      `This will clear Cloudflare CDN cache on all LP tlds (.com, .it, .fr, ...). ` +
      `Please allow 30s-2m for the operation to take full effect. ` +
      `Are you sure you want to proceed?`
    if (window.confirm(confirmMsg)) {
      setPurging(true)
      await clearCloudflareCache()
      setPurging(false)
      alert(
        `Cleared all Cloudflare CDN cache for LP/Wordpress pages, ` +
          `on all connected domains / TLDs (packhelp.com, .it, .fr, .de, etc..). ` +
          `Check console for details.`
      )
    }
  }
  const label = purging ? "Clearing.." : "Clear Cloudflare Cache"

  return (
    <Button
      disabled={purging}
      onClick={triggerCloudflareCachePurge.bind(this)}
      variant="contained"
      color="primary"
      size="small"
    >
      {label}
    </Button>
  )
}

export default ActionPurgeCloudflareCache
