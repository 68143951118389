import findIndex from "lodash/findIndex"
import isEqual from "lodash/isEqual"
import produce from "immer"
import React, { useState, useEffect } from "react"

import Container from "@material-ui/core/Container"

import "../../App.css"
import { db, serverLocalStateManager, session } from "../../data/data"

import LoaderWithPadding from "../../components/LoaderWithPadding"
import { HighlightedServers } from "./HighlightedServers"

import { ServersViewActions } from "./ServersViewActions"
import { BusyServers } from "./BusyServers"
import { FreeServers } from "./FreeServers"
import { UserServers } from "./UserServers"
import { FavoriteServers } from "./FavoriteServers"
import { ViewModelServer } from "../../data/view-model.server"

export default function ServersView({ user }) {
  const [loading, setLoading] = useState(true)
  const [servers, setServers] = useState([])

  useEffect(
    function loadAndSyncFirebaseServers() {
      db.collection("servers").onSnapshot((snapshot) => {
        const favoriteServers = session.getSettings().favoriteServers
        const snapshotServers = snapshot.docs
          .map((doc) => doc.data())
          .map((server) => {
            server.isFavorite = favoriteServers.includes(server.name)
            return server
          })
        setServers((servers) =>
          produce(servers, (draftState) => {
            let entriesLength = 0
            for (const [i, server] of snapshotServers.entries()) {
              if (!isEqual(draftState[i], server)) {
                draftState[i] = server
              }
              ++entriesLength
            }
            draftState.length = entriesLength
          })
        )
        setLoading(false)
      })
    },
    [user.uid]
  )

  useEffect(function syncLocalServers() {
    serverLocalStateManager.listen((serverId, dataUpdate) => {
      setServers((servers) =>
        produce(servers, (draftState) => {
          const updatedIndex = findIndex(draftState, { name: serverId })
          draftState[updatedIndex] = Object.assign(
            draftState[updatedIndex],
            dataUpdate
          )
        })
      )
    })
  }, [])
  const serverView = new ViewModelServer(servers, user.uid)

  return (
    <Container>
      <ServersViewActions />

      {loading ? (
        <LoaderWithPadding />
      ) : (
        <Container maxWidth="lg" style={{ padding: "10px 10px 70px 10px" }}>
          {serverView.anyFavorites() && (
            <FavoriteServers servers={serverView.favorites()} />
          )}

          {serverView.anyUserServers() && (
            <UserServers servers={serverView.userServers()} />
          )}

          {serverView.anyFreeDevServerAvailable() && (
            <FreeServers servers={serverView.freeDevServers()} />
          )}

          {serverView.anyBusyServers() && (
            <BusyServers servers={serverView.busyServers()} />
          )}

          <HighlightedServers servers={serverView.specialServers()} />
        </Container>
      )}
    </Container>
  )
}
