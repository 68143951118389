import React, { useState } from "react"

import { Typography, Button, MenuItem, Menu, Tooltip } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import ErrorIcon from "@material-ui/icons/Error"
import HelpIcon from "@material-ui/icons/Help"

import { JOB_STATUS, PENDING_JOB_STATUSES } from "../const/jobs"
import { DYNO_PRESET } from "../const/heroku"
import { time } from "../utils/time"
import { TimeElapsed } from "./../views/Time"

const ActionRowCreatorDBReset = ({
  actionStartedTimestamp,
  buttonLabel,
  disabled,
  hideResetToDev,
  herokuCreatorDynoScale,
  jobStatus,
  lastActionFinishedTimestamp,
  lastActionStartedTimestamp,
  lastDate,
  lastStatus,
  lastUrl,
  name,
  actionHandler,
  tooltipTitle = "No tooltip",
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const isPending = PENDING_JOB_STATUSES.includes(jobStatus)
  const jobDuration = time.timeInSecondsToFormattedTime(
    lastActionFinishedTimestamp - lastActionStartedTimestamp
  )

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const snapshotProvider = "aws"
  const snapshotProviderLabel = "RDS/S3"

  const resetToProd = () => {
    if (herokuCreatorDynoScale === DYNO_PRESET.DEFAULT) {
      window.alert(
        "Can't reset DB to PROD with dynos default scale. " +
          "Production DB is large and the db restore causes OOM " +
          "(out of memory) errors if there's not enough RAM. " +
          "To proceed, first scale the server up to one of " +
          "HIGH, E2E, or E2E_FAST presets."
      )
      return false
    }
    if (window.confirm("Are you sure? This is a long process.")) {
      actionHandler({ source: "production", snapshotProvider })
    }
  }

  const resetToDev = () => {
    actionHandler({ source: "dev", snapshotProvider })
  }

  const handleClose = (source) => {
    if (source === "production") {
      resetToProd()
    } else if (source === "dev") {
      resetToDev()
    }
    setAnchorEl(null) // close
  }

  return (
    <Box className="app-state-row" style={{ marginLeft: "0px" }}>
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Box
            style={{ display: "flex", alignItems: "center", minWidth: "135px" }}
          >
            <Typography style={{ fontSize: "0.875rem", marginRight: "10px" }}>
              {name}
            </Typography>
            <Tooltip title={tooltipTitle} style={{ marginLeft: "10px" }}>
              <HelpIcon style={{ color: "#aaa", fontSize: "0.875rem" }} />
            </Tooltip>
          </Box>
          <span style={{ marginLeft: "10px" }}>
            {(lastUrl && (
              <a href={lastUrl} target="_blank" rel="noopener noreferrer">
                <span>{time.timestampToFormattedDate(lastDate)}</span>
              </a>
            )) || <span>{time.timestampToFormattedDate(lastDate)}</span>}
          </span>
          <span style={{ marginLeft: "10px" }}>
            {lastStatus === JOB_STATUS.ERROR ? (
              <ErrorIcon
                style={{
                  height: 17,
                  position: "relative",
                  top: 4,
                  color: "#e6543c",
                }}
              />
            ) : (
              <CheckCircleIcon
                style={{
                  height: 17,
                  position: "relative",
                  top: 4,
                  color: "#28cc5c",
                }}
              />
            )}
          </span>
          <span style={{ marginLeft: "10px" }}>
            <span style={{ fontSize: "15px" }}>⏱</span> {jobDuration}
          </span>
        </Box>

        <Box style={{ display: "flex" }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{
              minWidth: "160px",
              maxHeight: "25px",
              lineHeight: "16px",
              fontSize: "11px",
            }}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleClick}
            disabled={disabled}
          >
            {!isPending ? (
              <span>{buttonLabel || "RUN"}</span>
            ) : (
              <TimeElapsed
                isClockTicking={isPending}
                startTimestamp={actionStartedTimestamp}
                endTimestamp={lastActionFinishedTimestamp}
              />
            )}
          </Button>

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              disabled={hideResetToDev}
              onClick={() => handleClose("dev")}
            >
              Reset to DEV (from {snapshotProviderLabel})
            </MenuItem>
            <MenuItem onClick={() => handleClose("production")}>
              Reset to PROD (from {snapshotProviderLabel})
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </Box>
  )
}

export default ActionRowCreatorDBReset
