import React from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import ErrorIcon from "@material-ui/icons/Error"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"

import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
  actionsBar: {
    marginTop: "10px",
  },
  runE2EButton: {
    height: "30px",
  },
  resetButton: {
    marginLeft: "10px",
  },
  spinner: {
    top: "3px",
    position: "relative",
    marginRight: "5px",
  },
  card: {
    "margin-top": "20px",
    minWidth: 275,
    position: "sticky",
  },
  chip: {
    marginTop: "5px",
    marginRight: "5px",
  },
  title: {
    fontSize: 14,
  },
  timeIcon: {
    fontSize: 17,
    position: "relative",
    top: 3,
  },
  statusIsIdle: {},
  statusIsPassed: {
    color: "white",
    backgroundColor: "#28cc5c",
  },
  statusIsFailed: {
    color: "white",
    backgroundColor: "#d30f12",
  },
  statusIsRunning: {
    backgroundColor: "#ffb000",
  },
  stateIcon: {
    height: 17,
    position: "relative",
    top: 3,
    color: "gray",
  },
})

function StateRow(props) {
  let {
    title,
    shouldDisplayMissingDataMessage,
    shouldDisplayGoodMessage,
    goodMessage,
    badMessage,
  } = props
  const classes = useStyles()

  return shouldDisplayMissingDataMessage ? (
    <TableRow key={title}>
      <TableCell component="th" scope="row" style={{ width: "50%" }}>
        {title}
      </TableCell>
      <TableCell>
        <VisibilityOffIcon
          className={classes.stateIcon}
          style={{ color: "gray" }}
        />{" "}
        Missing information
      </TableCell>
    </TableRow>
  ) : (
    <TableRow key={title}>
      <TableCell component="th" scope="row" style={{ width: "50%" }}>
        {title}
      </TableCell>
      <TableCell>
        {shouldDisplayGoodMessage ? (
          <div>
            <CheckCircleIcon
              className={classes.stateIcon}
              style={{ color: "#28cc5c" }}
            />{" "}
            {goodMessage}
          </div>
        ) : (
          <div>
            <ErrorIcon
              className={classes.stateIcon}
              style={{ color: "#e6543c" }}
            />{" "}
            {badMessage}
          </div>
        )}
      </TableCell>
    </TableRow>
  )
}

export function AppsStateCard(props) {
  const { server } = props
  const classes = useStyles()

  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Landing app state
          </Typography>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableBody>
              <StateRow
                title="Deployed sha"
                shouldDisplayMissingDataMessage={
                  typeof server.landingLastDeploymentCommit === "undefined" ||
                  typeof server.branchLastCommit === "undefined"
                }
                shouldDisplayGoodMessage={
                  server.landingLastDeploymentCommit === server.branchLastCommit
                }
                goodMessage="Latest"
                badMessage="Outdated"
              />
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardContent>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            Creator app state
          </Typography>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableBody>
              <StateRow
                title="Deployed sha"
                shouldDisplayMissingDataMessage={
                  typeof server.creatorLastDeploymentCommit === "undefined" ||
                  typeof server.branchLastCommit === "undefined"
                }
                shouldDisplayGoodMessage={
                  server.creatorLastDeploymentCommit === server.branchLastCommit
                }
                goodMessage="Latest"
                badMessage="Outdated"
              />
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  )
}
