/* global firebase */
import { CONFIG } from "../config"
import { time } from "../utils/time"
import { JOB_STATUS, JOB_TYPE } from "./../const/jobs"
import { updateServerData } from "./data"
import { triggerWorkflowJob } from "./workflowJobs"

let functions = firebase.app().functions(CONFIG.CLOUD_FN_REGION)

const triggerE2E = (E2EJobData) =>
  functions
    .httpsCallable("triggerE2EGreen")(E2EJobData)
    .catch((err) => {
      console.error(err)
      throw err
    })

const resetE2EDataOnServer = (serverName) => {
  updateServerData(serverName, {
    E2EJobStatus: JOB_STATUS.IDLE,
  })
}

async function runTests(params) {
  await updateServerData(params.serverName, {
    E2EJobStatus: JOB_STATUS.TRIGGERED,
  })
  return triggerWorkflowJob({
    repo: "packhelp/packhelp",
    workflow: "e2e-run.yml",
    jobType: JOB_TYPE.TEST_E2E,
    gitRef: params.branch,
    env: params.serverName,
    triggeredByUID: params.triggeredByUID,
    options: {
      workflowInputs: {
        concurrency: String(params.concurrency),
        triggeredby: params.triggeredByEmail,
        tags: params.tags && params.tags.join(","),
      },
      cb: async () => {
        await updateServerData(params.serverName, {
          E2EJobFinishedTimestamp: null,
          E2EJobNumber: "n/a",
          E2EJobStartedTimestamp: time.getTimeNowInSeconds(),
        })
      },
      cbOnFail: async () => {
        await updateServerData(params.serverName, {
          E2EJobStatus: JOB_STATUS.ERROR,
          E2EJobNumber: "n/a",
        })
      },
    },
  })
}

async function runE2ETests(params) {
  return runTests(params)
}

export { triggerE2E, runE2ETests, resetE2EDataOnServer }
