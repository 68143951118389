/* global firebase */
import { JOB_STATUS_NEW } from "./../const/jobs"
import { createJob, updateJob } from "../jobs"
import { runHttpsCallableFn } from "./httpsCallable"

export async function triggerWorkflowJob({
  repo,
  workflow,
  jobType,
  gitRef,
  env,
  triggeredByUID,
  options,
}) {
  const opts = Object.assign(
    {
      meta: {},
      workflowInputs: {},
      cb: null, // callback to run on success
      cbOnFail: null, // callback to run on fail
    },
    options
  )
  const { meta, workflowInputs } = opts
  const jobId = await createJob({
    branch: gitRef,
    type: jobType,
    server: env,
    triggeredByUID,
    meta: meta || {},
  })
  const [workflowOrg, workflowRepo] = repo.split("/")
  try {
    await runHttpsCallableFn("triggerGithubWorkflow", {
      workflow: {
        org: workflowOrg,
        repo: workflowRepo,
        workflowName: workflow,
      },
      gitRef: gitRef,
      inputs: Object.assign(
        {
          job_id: jobId,
          env: env,
        },
        workflowInputs
      ),
    })
    if (opts.cb) {
      await opts.cb()
    }
  } catch (err) {
    await updateJob(jobId, {
      error: String(err),
      status: JOB_STATUS_NEW.FAILED,
      startedTimestamp: firebase.firestore.Timestamp.now(),
      finishedTimestamp: firebase.firestore.Timestamp.now(),
    })
    if (opts.cbOnFail) {
      await opts.cbOnFail(err)
    }
    alert(String(err))
    throw err
  }
}
