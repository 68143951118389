import React from "react"

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
// import Switch from '@material-ui/core/Switch'
// import SyncIcon from '@material-ui/icons/Sync'
// import SyncDisabledIcon from '@material-ui/icons/SyncDisabled'
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import ErrorIcon from "@material-ui/icons/Error"

import { TimeElapsed } from "./../views/Time"

import { JOB_STATUS, PENDING_JOB_STATUSES } from "../const/jobs"
import { time } from "../utils/time"
// import {updateServerData} from '../data/data'

const AppStateRow = (props) => {
  const {
    // serverName,
    iconSrc,
    iconEmoji,
    // autoDeploy,
    // autoDeployName,
    deployButtonLabel,
    deploymentStatus,
    deploymentAllowed,
    deploymentStartedTimestamp,
    lastDeploymentCommitSha,
    lastDeploymentURL,
    lastDeploymentTimestamp,
    lastDeploymentStartedTimestamp,
    lastDeploymentFinishedTimestamp,
    triggerDeployment,
    // isProduction,
  } = props

  const jobDuration = time.timeInSecondsToFormattedTime(
    lastDeploymentFinishedTimestamp - lastDeploymentStartedTimestamp
  )

  const isPending = PENDING_JOB_STATUSES.includes(deploymentStatus)

  const shortSha = () => {
    return lastDeploymentCommitSha
      ? `${lastDeploymentCommitSha}`.substring(0, 8)
      : ""
  }

  // const toggleAutoDeploy = event => {
  //   let dataUpdate = {}
  //   dataUpdate[autoDeployName] = event.target.checked
  //   updateServerData(serverName, dataUpdate)
  // }

  const commitURL = () => {
    if (!lastDeploymentCommitSha) {
      return ""
    }
    const repoURL = "https://github.com/packhelp/packhelp"
    return `${repoURL}/commit/${lastDeploymentCommitSha}`
  }

  return (
    <Box className="app-state-row">
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <Box style={{ display: "flex", alignItems: "center" }}>
          {iconSrc && (
            <img src={iconSrc} alt="State Icon" style={{ height: "16px" }} />
          )}
          {iconEmoji && (
            <span alt="State Icon" style={{ fontSize: "16px" }}>
              {iconEmoji}
            </span>
          )}
          {lastDeploymentCommitSha ? (
            <span style={{ marginLeft: "10px" }}>
              <a
                href={lastDeploymentURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>
                  {time.timestampToFormattedDate(
                    lastDeploymentFinishedTimestamp
                  )}
                </span>
              </a>
              <span style={{ marginLeft: "10px" }}>
                {deploymentStatus === JOB_STATUS.ERROR ? (
                  <ErrorIcon
                    style={{
                      height: 17,
                      position: "relative",
                      top: 4,
                      color: "#e6543c",
                    }}
                  />
                ) : (
                  <CheckCircleIcon
                    style={{
                      height: 17,
                      position: "relative",
                      top: 4,
                      color: "#28cc5c",
                    }}
                  />
                )}
                <a href={commitURL()} target="_blank" rel="noopener noreferrer">
                  {shortSha()}
                </a>
              </span>
              <span style={{ marginLeft: "10px" }}>
                <span style={{ fontSize: "15px" }}>⏱</span> {jobDuration}
              </span>
            </span>
          ) : (
            <span style={{ marginLeft: "10px", color: "#9f9f9f" }}>
              No deploys yet
            </span>
          )}
        </Box>

        <Box style={{ display: "flex" }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{
              minWidth: "160px",
              maxHeight: "25px",
              lineHeight: "16px",
              fontSize: "11px",
            }}
            target="_blank"
            rel="noopener noreferrer"
            onClick={triggerDeployment}
            disabled={!deploymentAllowed}
          >
            {!isPending ? (
              <span>{deployButtonLabel || "Deploy"}</span>
            ) : (
              <TimeElapsed
                isClockTicking={isPending}
                startTimestamp={deploymentStartedTimestamp}
                endTimestamp={lastDeploymentTimestamp}
              />
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default AppStateRow
