/* global firebase */
import React from "react"
import ReactDOM from "react-dom"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"

import "./index.css"

import * as serviceWorker from "./serviceWorker"
import { session, getUsers, getPermissions } from "./data/data"
import { App } from "./App"
import { LoginScreen } from "./Login"
import { isUserAllowed } from "./validation"
import { env } from "./utils/env"

Sentry.init({
  dsn: env("REACT_APP_SENTRY_DSN"),
  integrations: [new Integrations.BrowserTracing()],
  environment: env("REACT_APP_SENTRY_ENV"),
  release: env("REACT_APP_VERSION"),
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.1,
})

firebase.auth().onAuthStateChanged((user) => {
  if (!user || !isUserAllowed(user)) {
    // No user is signed in. Render login screen
    ReactDOM.render(<LoginScreen />, document.getElementById("root"))
    return
  }

  // Preload users
  getUsers()
  session.set(user)
  getPermissions().then((permissions) => {
    session.setPermissions(permissions)
    ReactDOM.render(<App initialUser={user} />, document.getElementById("root"))
  })
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
