import React from "react"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

export const ServersListHeadingWithToggle = (props) => {
  const { title, isExpanded, onToggleClick } = props
  return (
    <div style={{ display: "grid", gridTemplateColumns: "1fr 8fr 2fr" }}>
      {isExpanded ? (
        <Button
          variant="text"
          color="secondary"
          onClick={onToggleClick}
          style={{ minWidth: 150 }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ExpandLessIcon />
          {" Less"}
        </Button>
      ) : (
        <Button
          variant="text"
          color="primary"
          onClick={onToggleClick}
          style={{ minWidth: 150 }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ExpandMoreIcon />
          {" More"}
        </Button>
      )}
      <Typography variant="h5" gutterBottom className="heading-separator">
        {title}
      </Typography>
    </div>
  )
}
