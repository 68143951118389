import React from "react"

import { bindPopover } from "material-ui-popup-state/hooks"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import FormControl from "@material-ui/core/FormControl"
import Input from "@material-ui/core/Input"
import InputLabel from "@material-ui/core/InputLabel"
import Popover from "@material-ui/core/Popover"

const EditPopover = ({
  state,
  onClose,
  label,
  value,
  onChange,
  multiline,
  rowsMax,
  closeOnEnter,
}) => {
  const popoverPosition = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && closeOnEnter) {
      onClose()
    }
  }

  return (
    <Popover
      {...bindPopover(state)}
      {...popoverPosition}
      style={{ marginTop: "7px" }}
      onClose={onClose}
    >
      <Card style={{ width: 300 }}>
        <CardContent>
          <FormControl fullWidth>
            <InputLabel>{label}</InputLabel>
            <Input
              autoFocus={true}
              value={value}
              onChange={onChange}
              onKeyDown={handleKeyDown}
              multiline={multiline}
              rowsMax={rowsMax}
            />
          </FormControl>
        </CardContent>
        <CardActions>
          <Button size="small" color="primary" onClick={onClose}>
            Save
          </Button>
        </CardActions>
      </Card>
    </Popover>
  )
}

export default EditPopover
