import React, { useState, useEffect } from "react"
import { time } from "../utils/time"
// import {
//   FINISHED_JOB_STATUSES,
//   PENDING_JOB_STATUSES,
//   JOB_STATUS,
// } from '../const/jobs'

export function TimeElapsed({
  isClockTicking,
  startTimestamp,
  endTimestamp,
  reverse = false,
  timeout = true,
}) {
  const [currentTimestamp, setCurrentTimestamp] = useState(
    time.getTimeNowInSeconds()
  )
  const timeoutInSeconds = 3600

  useEffect(() => {
    let interval
    if (isClockTicking) {
      interval = setInterval(() => {
        setCurrentTimestamp(time.getTimeNowInSeconds())
      }, 1000)
    }
    return () => clearInterval(interval)
  }, [isClockTicking])

  const endSeconds = isClockTicking ? currentTimestamp : endTimestamp
  const startSeconds = startTimestamp
  let timedeltaFormatted
  if (reverse) {
    timedeltaFormatted = time.timeInSecondsToFormattedTime(
      startSeconds - endSeconds,
      true
    )
  } else {
    timedeltaFormatted = time.timeInSecondsToFormattedTime(
      endSeconds - startSeconds,
      true
    )
  }

  const isTimedOut =
    parseInt(endSeconds) > parseInt(startTimestamp) + timeoutInSeconds

  if (isTimedOut && timeout) {
    return <span>&#62;60:00</span>
  } else {
    return <span>{timedeltaFormatted}</span>
  }
}
