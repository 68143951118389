// TODO: remove this and replace everywhere /w JOB_STATUS_NEW then rename
export const JOB_STATUS = Object.freeze({
  IDLE: "Idle",
  TRIGGERED: "Triggered",
  STARTING: "Starting",
  DEPLOYING: "Deploying",
  RUNNING: "Running",
  TESTING: "Testing",
  PASSED: "Passed",
  FAILED: "Failed",
  ERROR: "Error",
})

export const RUNNING_JOB_STATUSES = [
  JOB_STATUS.STARTING,
  JOB_STATUS.DEPLOYING,
  JOB_STATUS.RUNNING,
  JOB_STATUS.TESTING,
]
export const PENDING_JOB_STATUSES = [
  JOB_STATUS.TRIGGERED,
  JOB_STATUS.STARTING,
  JOB_STATUS.RUNNING,
  JOB_STATUS.DEPLOYING,
  JOB_STATUS.TESTING,
]
export const FINISHED_JOB_STATUSES = [
  JOB_STATUS.IDLE,
  JOB_STATUS.PASSED,
  JOB_STATUS.FAILED,
]

export const JOB_STATUS_NEW = Object.freeze({
  CREATED: "CREATED",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
})

export const JOB_TYPE = Object.freeze({
  DEPLOY_CREATOR: "DEPLOY_CREATOR",
  DEPLOY_CREATOR_K8S: "DEPLOY_CREATOR_K8S",
  DEPLOY_LANDING: "DEPLOY_LANDING",
  DEPLOY_LANDING_K8S: "DEPLOY_LANDING_K8S",
  DEPLOY_PLATFORM: "DEPLOY_PLATFORM",
  DEPLOY_PLATFORM_K8S: "DEPLOY_PLATFORM_K8S",
  TEST_E2E: "TEST_E2E",
  RESET_CREATOR_DB_DEV: "RESET_CREATOR_DB_DEV",
  RESET_CREATOR_DB_DEV_K8S: "RESET_CREATOR_DB_DEV_K8S",
  RESET_CREATOR_DB_PROD: "RESET_CREATOR_DB_PROD",
  RESET_CREATOR_DB_PROD_K8S: "RESET_CREATOR_DB_PROD_K8S",
  MIGRATE_LANDING_DB_DEV: "MIGRATE_LANDING_DB_DEV",
  MIGRATE_LANDING_DB_DEV_K8S: "MIGRATE_LANDING_DB_DEV_K8S",
  CREATE_DEV_SERVER: "CREATE_DEV_SERVER",
  CREATE_DEV_SERVER_BLUE: "CREATE_DEV_SERVER_BLUE",
  DESTROY_DEV_SERVER: "DESTROY_DEV_SERVER",
})

export const JOB_TYPE_HUMAN_READABLE = Object.freeze({
  [JOB_TYPE.DEPLOY_CREATOR]: "Deploy Creator (Legacy Heroku)",
  [JOB_TYPE.DEPLOY_CREATOR_K8S]: "Deploy Creator",
  [JOB_TYPE.DEPLOY_LANDING]: "Deploy Landing",
  [JOB_TYPE.DEPLOY_LANDING_K8S]: "Deploy Landing (Legacy)",
  [JOB_TYPE.DEPLOY_PLATFORM]: "Deploy Platform",
  [JOB_TYPE.DEPLOY_PLATFORM_K8S]: "Deploy Platform (Legacy)",
  [JOB_TYPE.TEST_E2E]: "Trigger E2E Tests",
  [JOB_TYPE.CREATE_DEV_SERVER]: "Create Dev Server",
  [JOB_TYPE.CREATE_DEV_SERVER_BLUE]: "Create Dev Server (Blue)",
  [JOB_TYPE.DESTROY_DEV_SERVER]: "Destroy Dev Server",
  [JOB_TYPE.RESET_CREATOR_DB_DEV]: "Reset Creator DB to DEV (Legacy Heroku)",
  [JOB_TYPE.RESET_CREATOR_DB_DEV_K8S]: "Reset Creator DB to DEV",
  [JOB_TYPE.RESET_CREATOR_DB_PROD]:
    "Reset Creator DB to PRODUCTION (Legacy Heroku)",
  [JOB_TYPE.RESET_CREATOR_DB_PROD_K8S]: "Reset Creator DB to PRODUCTION",
  [JOB_TYPE.MIGRATE_LANDING_DB_DEV]: "Migrate Landing DB to DEV (Legacy)",
  [JOB_TYPE.MIGRATE_LANDING_DB_DEV_K8S]: "Migrate Landing DB to DEV",
})

export const JOB_RUNNER = Object.freeze({
  GITLAB: "GITLAB",
  GITHUB: "GITHUB",
})

export const AVAILABLE_JOB_RUNNERS = [JOB_RUNNER.GITHUB]
