import { env } from "./env"
import uniqueId from "lodash/uniqueId"
import has from "lodash/has"

class FeatureFlags {
  constructor() {
    const DEFAULT_FEATURE_FLAGS_STATE = {
      REACT_APP_LANDING_DEPLOYMENT_ENABLED: true,
      REACT_APP_CREATOR_DEPLOYMENT_ENABLED: true,
      REACT_APP_PLATFORM_DEPLOYMENT_ENABLED: true,
    }
    this._flags = this.flagsWithEnvOverride(DEFAULT_FEATURE_FLAGS_STATE)
    this._subscribers = {}
  }

  normalizedFlagType(value) {
    const acceptedValues = ["true", "false", true, false]
    if (!acceptedValues.includes(value)) {
      throw new Error(
        `Unaccepted flag value type. Use one of ${acceptedValues}.`
      )
    }
    const typeMappings = {
      true: true,
      false: false,
    }
    return has(typeMappings, value) ? typeMappings[value] : value
  }

  flagsWithEnvOverride(flags) {
    const newFlags = Object.assign({}, flags)
    for (const key of Object.keys(newFlags)) {
      if (env(key) !== undefined) {
        const normalized = this.normalizedFlagType(env(key))
        newFlags[key] = normalized
      }
    }
    return newFlags
  }

  publish(flags) {
    for (const fn of Object.values(this._subscribers)) {
      fn(flags)
    }
  }

  subscribe(fn) {
    const subId = uniqueId()
    this._subscribers[subId] = fn
    const unsubscribe = () => delete this._subscribers[subId]
    return unsubscribe
  }

  update(key, value) {
    this._flags = Object.assign({}, this._flags, { [key]: value })
    this.publish(this._flags)
  }

  get(key) {
    if (this._flags[key] === undefined) {
      throw new Error(`No flag named ${key}.`)
    }
    return this._flags[key]
  }

  get raw() {
    return this._flags
  }
}

const Flags = new FeatureFlags()
window.flags = Flags

export { Flags }
