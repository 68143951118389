/* global firebase */
import { JOB_STATUS_NEW, AVAILABLE_JOB_RUNNERS, JOB_RUNNER } from "./const/jobs"

let db = firebase.firestore()

export const createJob = (params) => {
  console.log("createJob with params: ", params)
  const { branch, type, server, runner } = params
  if (!branch || !type || !server) {
    throw new Error("createJob: invalid params (need branch, type, server)")
  }
  const job = Object.assign(
    {
      created: firebase.firestore.Timestamp.now(),
      startedTimestamp: firebase.firestore.Timestamp.now(),
      runner: runner || JOB_RUNNER.GITHUB,
      status: JOB_STATUS_NEW.IN_PROGRESS,
      triggeredManually: true,
    },
    params
  )
  if (!AVAILABLE_JOB_RUNNERS.includes(job.runner)) {
    throw new Error("createJob: incorrect runner used")
  }
  return db
    .collection("jobs")
    .add(job)
    .then((ref) => {
      console.log("Added doc:", ref.id)
      return ref.id
    })
}

export const updateJob = (id, params) => {
  return db
    .collection("jobs")
    .doc(id)
    .update(params)
    .then(() => {
      console.log(`Updated ${id}`)
    })
}
