/* global firebase */
import { CONFIG } from "../config"
let functions = firebase.app().functions(CONFIG.CLOUD_FN_REGION)

/**
 * Call like this

  const out = await runHttpsCallableFn("functionName", {
    param1: ...,
    param2: ...,
    ...
  })

*/

export async function runHttpsCallableFn(name, params) {
  console.log(`Calling httpsCallable fn: ${name} with params: `, params)
  return new Promise((resolve, reject) => {
    let fn = functions.httpsCallable(name)
    fn(params)
      .then((result) => {
        if (result.data && result.data.failed) {
          console.log(result)
          reject(`${name} failure: ${result.data.fail_reason}`)
        }
        resolve(result)
      })
      .catch((err) => {
        console.log(err)
        reject(`${name} failure: ${String(err)}`)
      })
  })
}
