import React from "react"

import { Typography, Tooltip } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import HelpIcon from "@material-ui/icons/Help"

import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"

const ActionAutomationRowCreatorDBReset = ({
  name,
  onCheckboxClick,
  tooltipTitle = "No tooltip",
  creatorDailyDBReset,
}) => {
  const handleOnChange = () => {
    onCheckboxClick(!creatorDailyDBReset)
  }

  return (
    <Box className="app-state-row" style={{ marginLeft: "0px" }}>
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Box
            style={{ display: "flex", alignItems: "center", minWidth: "135px" }}
          >
            <Typography style={{ fontSize: "0.875rem", marginRight: "10px" }}>
              {name}
            </Typography>
            <Tooltip title={tooltipTitle} style={{ marginLeft: "10px" }}>
              <HelpIcon style={{ color: "#aaa", fontSize: "0.875rem" }} />
            </Tooltip>
          </Box>
          <span style={{ marginLeft: "10px" }}>Daily @ 2:00 AM UTC</span>
        </Box>
        <Box style={{ display: "flex" }}>
          <FormControlLabel
            label={creatorDailyDBReset ? "Enabled" : "Disabled"}
            control={
              <Checkbox
                checked={creatorDailyDBReset}
                onChange={handleOnChange}
              />
            }
          />
        </Box>
      </Box>
    </Box>
  )
}

export default ActionAutomationRowCreatorDBReset
