import React from "react"
import AppStateRow from "./AppStateRow"
import Box from "@material-ui/core/Box"

/* For now its wrapper for AppStateRow.
You can pass ActionRow in slot to render them under service
*/
const Service = (props) => {
  const {
    serverName,
    iconSrc,
    iconEmoji,
    autoDeploy,
    autoDeployName,
    deployButtonLabel,
    deploymentStatus,
    deploymentAllowed,
    deploymentStartedTimestamp,
    lastDeploymentCommitSha,
    lastDeploymentNumber,
    lastDeploymentURL,
    lastDeploymentStartedTimestamp,
    lastDeploymentFinishedTimestamp,
    triggerDeployment,
    isProduction,
  } = props
  return (
    //App Actions
    <Box>
      <AppStateRow
        serverName={serverName}
        iconSrc={iconSrc}
        iconEmoji={iconEmoji}
        autoDeploy={autoDeploy}
        autoDeployName={autoDeployName}
        deploymentStatus={deploymentStatus}
        deploymentAllowed={deploymentAllowed}
        deployButtonLabel={deployButtonLabel}
        deploymentStartedTimestamp={deploymentStartedTimestamp}
        lastDeploymentCommitSha={lastDeploymentCommitSha}
        lastDeploymentNumber={lastDeploymentNumber}
        lastDeploymentURL={lastDeploymentURL}
        lastDeploymentStartedTimestamp={lastDeploymentStartedTimestamp}
        lastDeploymentFinishedTimestamp={lastDeploymentFinishedTimestamp}
        triggerDeployment={triggerDeployment}
        isProduction={isProduction}
      />
      {props.children}
    </Box>
  )
}

export default Service
