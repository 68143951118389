import React from "react"

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Chip from "@material-ui/core/Chip"
import Avatar from "@material-ui/core/Avatar"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import Tooltip from "@material-ui/core/Tooltip"

import VpnKeyIcon from "@material-ui/icons/VpnKey"

import "./App.css"
import { userSignOut, userLogin } from "./data/auth"
import { session } from "./data/data"

function LoginButton() {
  return (
    <Button
      style={{ marginLeft: 15 }}
      variant="contained"
      size="large"
      onClick={userLogin}
      color="primary"
    >
      Login
    </Button>
  )
}

export function LoggedUserPermissionsHint({ permissions }) {
  if (!permissions || !permissions.length) {
    return null
  }
  const message = `Your permissions: ${permissions.join(", ")}.`
  return (
    <Tooltip title={message}>
      <Chip label={<VpnKeyIcon style={{ color: "#a7a7a7" }} />} />
    </Tooltip>
  )
}

export function LoggedUserBadge({ user, style }) {
  return (
    <Chip
      avatar={<Avatar alt={user.displayName} src={user.photoURL} />}
      label={user.email}
      style={style}
      onDelete={userSignOut}
      deleteIcon={<ExitToAppIcon />}
    />
  )
}

export function LoginScreen({ user }) {
  return (
    <Container maxWidth="sm" style={{ marginTop: "25vh" }}>
      <Paper style={{ padding: "30px 0" }}>
        <Grid container direction="column" justify="center" alignItems="center">
          <Typography variant="h5">
            DEPLOY DASHBOARD{" "}
            <span role="img" aria-label="Box emoji">
              📦
            </span>
          </Typography>
          <Box style={{ margin: "15px 0" }}>
            {user ? (
              <Box>
                <LoggedUserPermissionsHint
                  permissions={session.getPermissions()}
                />
                <LoggedUserBadge user={user} />
              </Box>
            ) : (
              <LoginButton />
            )}
          </Box>
        </Grid>
        <Typography variant="body2" align="center" gutterBottom>
          Please login using Packhelp Google Account
        </Typography>
      </Paper>
    </Container>
  )
}
